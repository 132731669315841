// import { useAuth } from "oidc-react";

// import { Loader } from "@shared/CircleLoader";

export const HomeComponent = () => {

  return (
    <div>
      <h1>Home</h1>
      <h1>HomePage</h1>
      <a href="https://checkout-dev.bmwdr.io/lane">Press to Redirect</a>
      <p>Welcome to the home page!</p>
    </div>
  );
};
