// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { Theme, useTheme } from '@mui/material'
import {
  Box,
  Typography,
  Select,
  Button,
  Input,
  CircleLoader,
  Skeleton,
  Tooltip
} from '@ntpkunity/controls'
import {
  DeskingActionTypes,
  FinanceTypes,
  QuoteParams,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { useDebouncedState, useGetPricingCalculation } from '@apis/dealer-configurations.service'
import { AddVehicle, Programs } from './add-vehicle'
import { formatNumber, selectDefaultMileages } from './utils'
import { useStoreContext } from '@store/storeContext'
import { StipCodes } from '@helpers/enums/stipulation-type.enum'
import { PricingTypes } from '@helpers/enums/pricing-type.enum'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import { IIntegrationsResponse } from 'types/responses/companyIntegrations'
import { IntegrationTypeForProvider } from '@helpers/enums/integrations.enum'
import { useGetProviderByIntegrationType } from '@hooks/queries/useGetProviderByIntegrationType'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'

const VehicleLoader: FC<{ width: string | number; height?: string }> = ({
  width,
  height = '30px'
}) => {
  const theme = useTheme()

  return (
    <Skeleton
      theme={theme}
      variant="rectangular"
      width={width}
      height={height}
      sx={{ borderRadius: '8px' }}
    />
  )
}

export const Label: FC<{ name: string }> = ({ name }) => {
  const theme = useTheme()

  return <Typography theme={theme} variant="caption" component="div" children={name} />
}

export const Value: FC<{ value: string | number; isCurrency?: boolean }> = ({
  value,
  isCurrency = true
}) => {
  const theme = useTheme()
  const { formatCurrency } = useDeskingContext()

  return (
    <Typography
      theme={theme}
      variant="caption"
      component="div"
      className="amount"
      textAlign="center"
      children={isCurrency ? formatCurrency(value) : formatNumber(value)}
    />
  )
}

export const DownPayment: FC<{
  vin: string | undefined
  rowIndex: number
  colIndex: number
  value: string | number
}> = ({ vin, rowIndex, colIndex, value }) => {
  const theme = useTheme()
  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const { state, dispatch, formatCurrency } = useDeskingContext()
  const [debouncedState, setState, _state] = useDebouncedState(value)
  const canUserEdit = useEditPermissionHook()
  const { minimunDownPayment, maximumDownPayment } = state.isVehicleSelectedAndOrderSaved
    ? state.preview.down_payments
    : state.vehiclesForComparison === VehiclesForComparisonTypes.SINGLE
      ? state[state.vehiclesForComparison][state.finance_type].down_payments
      : state[state.vehiclesForComparison][state.finance_type][colIndex].down_payments

  useEffect(() => {
    let hasValidationError = false
    if (
      debouncedState >= minimunDownPayment &&
      debouncedState <= maximumDownPayment
    ) {
      if (Number(value) !== Number(debouncedState)) {
        dispatch({
          type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT,
          payload: {
            vinQPDP: vin as string,
            rowIndex,
            colIndex,
            value: Number(debouncedState ?? value)
          }
        })
      }
    } else hasValidationError = true

    dispatch({
      type: DeskingActionTypes.UPDATE_VALIDATION_ERRORS,
      payload: {
        validationIndex: colIndex,
        validationKey: 'down_payment',
        validationValue: hasValidationError
      }
    })
  }, [debouncedState])

  const doesErrorExists = _state < minimunDownPayment || _state > maximumDownPayment

  const isFinancedAmount: boolean =
    state.order?.order_submissions?.[0]?.decision?.stipulations?.some(
      (item: IStipulationResponse) => item?.stipulation_code === StipCodes.FINANCED_AMOUNT
    )

  return state.isProgramLoading ? (
    <VehicleLoader width={'100%'} />
  ) : (
    <Tooltip
      theme={theme}
      title={`The down payment should be in between ${formatCurrency(
        minimunDownPayment
      )} to ${formatCurrency(maximumDownPayment)}`}
      placement="top"
      disablePortal={false}
    >
      <Input
        theme={theme}
        type="text"
        fullWidth
        startAdornment={defaultCurrency?.symbol}
        value={_state}
        masking
        maskNumeric
        maskDecimalScale={2}
        onChange={(e) => setState(Number(e))}
        disabled={
          Boolean(state.submission_tier) ||
          (state.order?.order_submissions?.[0]?.decision?.stipulations && !isFinancedAmount) ||
          !canUserEdit
        }
        error={doesErrorExists ? _state : null}
      />
    </Tooltip>
  )
}

export const QuoteParam: FC<{
  vin: string | undefined
  rowIndex: number
  colIndex: number
  value: QuoteParams
}> = ({ vin, rowIndex, colIndex, value }) => {
  const theme = useTheme()
  const [getNewProgram, setGetNewProgram] = useState<{ term: number; mileage: number } | undefined>(
    undefined
  )
  const { states } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const [debouncedState, setState, _state] = useDebouncedState(value)
  const { data: integrationProvider } = useGetProviderByIntegrationType(
    states?.dealerInfo.dealer_code,
    IntegrationTypeForProvider.CALCULATION_AND_TAXATION,
    states?.dealerInfo?.company_id
  )
  const { contract_term, apr, rv_value, annual_usage } = _state ?? {}
  const { finance_type } = state
  const { minimumRv, maximumRv } = state[VehiclesForComparisonTypes.SINGLE][finance_type]
  const { contractTermOptionsFinance, contractTermOptionsLease, milleageOptions } = states?.dealDesk
  const isFinancedAmount: boolean =
    state.order?.order_submissions?.[0]?.decision?.stipulations?.some(
      (item: IStipulationResponse) => item?.stipulation_code === StipCodes.FINANCED_AMOUNT
    )
  const pricingExternal: boolean =
    states?.lendersData?.length > 0
      ? states?.lendersData?.find((st) => st?.id === state.lender_id)?.program ===
      PricingTypes.EXTERNAL
      : false

  const marketScanEnabled = integrationProvider?.providers?.some(
    (provider: IntegrationProvider) =>
      provider?.provider_name === IntegrationProvider.MARKET_SCAN && provider?.is_active
  )
  const isBalloonDisabledWithNoValue: boolean =
    finance_type === FinanceTypes.FINANCE && marketScanEnabled

  const canUserEdit = useEditPermissionHook()

  useEffect(() => {
    const isRvValueChanged = (Object.keys(debouncedState ?? {}).length > 0 &&
      Number(value.rv_value) !== Number(debouncedState.rv_value))
    const isRvErrorExists = ((isRvValueChanged && !pricingExternal)
      ? (debouncedState.rv_value < minimumRv || debouncedState.rv_value > maximumRv) : false)

    const hasChanged =
      Object.keys(debouncedState ?? {}).length > 0
        ? Number(value.apr) !== Number(debouncedState.apr) ||
        isRvValueChanged : true

    if (!hasChanged || isRvValueChanged) {
      dispatch({
        type: DeskingActionTypes.UPDATE_VALIDATION_ERRORS,
        payload: {
          validationIndex: rowIndex,
          validationKey: 'rv',
          validationValue: isRvErrorExists
        }
      })
    }

    if (hasChanged && !isRvErrorExists) {
      dispatch({
        type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT,
        payload: {
          vinQPDP: vin as string,
          rowIndex,
          colIndex,
          value: debouncedState ?? value,
          field: 'quote_params',
          restrictCaculations: false
        }
      })
    }
  }, [debouncedState?.apr, debouncedState?.rv_value])

  useEffect(() => {
    const isTermMileageChanged =
      Object.keys(debouncedState ?? {}).length > 0
        ? Number(value.contract_term) !== Number(debouncedState.contract_term) ||
        ((value.annual_usage !== undefined || value.annual_usage !== null) &&
          Number(value.annual_usage) !== Number(debouncedState.annual_usage))
        : false

    if (isTermMileageChanged) {
      dispatch({
        type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT,
        payload: {
          vinQPDP: vin as string,
          rowIndex,
          colIndex,
          value: debouncedState ?? value,
          field: 'quote_params',
          restrictCaculations: true
        }
      })
    }
  }, [debouncedState?.annual_usage, debouncedState?.contract_term])

  const contractTermsOptions =
    (vin ?? '').length > 0
      ? (finance_type === FinanceTypes.FINANCE
        ? contractTermOptionsFinance
        : contractTermOptionsLease
      )?.map((item) => {
        return {
          text: `${item?.term}mo`,
          value: item?.term
        }
      })
      : [{ text: `${contract_term}mo`, value: contract_term }]
  const milesOptions =
    (vin ?? '').length > 0
      ? milleageOptions
      : [
        {
          text: parseInt(annual_usage) ? `${parseInt(annual_usage) / 1000}k` : '0',
          value: annual_usage
        }
      ]

  const doesErrorExists = !pricingExternal ? rv_value < minimumRv || rv_value > maximumRv : false

  return (
    <Box theme={theme} className="flc-li-left-col">
      <Box theme={theme} className="flc-labels-wrap">
        <Box theme={theme} className="label-row">
          {getNewProgram?.term && (
            <Programs
              vin={vin}
              index={0}
              term={getNewProgram?.term}
              mileage={getNewProgram?.mileage}
              setter={setGetNewProgram}
              rowIndex={rowIndex}
            />
          )}
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children="Term"
          />
          {state.isProgramLoading ? (
            <VehicleLoader width={'125px'} />
          ) : (
            <Select
              theme={theme}
              fullWidth
              disablePortal
              items={contractTermsOptions}
              value={contract_term ?? '0'}
              onChange={(e) => {
                setState({ ..._state, contract_term: e.target.value })
                setTimeout(
                  () =>
                    setGetNewProgram({
                      term: e.target.value,
                      mileage: annual_usage
                    }),
                  1000
                )
              }}
              disabled={Boolean(state?.submission_tier) || !canUserEdit}
            />
          )}
        </Box>
        {finance_type === FinanceTypes.LEASE ? (
          <Box theme={theme} className="label-row">
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="label text-overflow"
              children="Miles"
            />
            {state.isProgramLoading ? (
              <VehicleLoader width={'125px'} />
            ) : (
              <Select
                theme={theme}
                fullWidth
                disablePortal
                items={milesOptions}
                value={annual_usage ?? '0'}
                onChange={(e) => {
                  setState({ ..._state, annual_usage: e.target.value })
                  setTimeout(
                    () =>
                      setGetNewProgram({
                        term: contract_term,
                        mileage: e.target.value
                      }),
                    1000
                  )
                }}
                disabled={
                  Boolean(state?.submission_tier) ||
                  (state.order?.order_submissions?.[0]?.decision?.stipulations &&
                    !isFinancedAmount) ||
                  !canUserEdit
                }
              />
            )}
          </Box>
        ) : null}
        <Box theme={theme} className="label-row">
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children="Rate"
          />
          {state.isProgramLoading ? (
            <VehicleLoader width={'125px'} />
          ) : (
            <Tooltip theme={theme} title="Please add range (0 - 100)" placement="right">
              <Input
                theme={theme}
                fullWidth
                type="text"
                value={apr}
                onChange={(e) =>
                  setState({
                    ..._state,
                    apr: formatNumber(Number(Math.max(0, Math.min(100, Number(e)))), 5, 5)
                  })
                }
                masking
                maskNumeric
                maskDecimalScale={5}
                endAdornment="%"
                disabled={
                  Boolean(state?.submission_tier) ||
                  (state.order?.order_submissions?.[0]?.decision?.stipulations &&
                    !isFinancedAmount) ||
                  !canUserEdit
                }
              />
            </Tooltip>
          )}
        </Box>
        {!isBalloonDisabledWithNoValue && (
          <Box theme={theme} className="label-row">
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="label text-overflow"
              children={`${finance_type === FinanceTypes.FINANCE ? 'Balloon' : 'RV'}`}
            />
            {state.isProgramLoading ? (
              <VehicleLoader width={'125px'} />
            ) : pricingExternal ? (
              <Input
                theme={theme}
                fullWidth
                type="text"
                value={rv_value}
                masking
                maskNumeric
                maskDecimalScale={2}
                endAdornment={'%'}
                disabled={pricingExternal || !canUserEdit}
              />
            ) : (
              <Tooltip
                theme={theme}
                title={`${finance_type === FinanceTypes.FINANCE ? 'Balloon' : 'RV'
                  } should be in range (${minimumRv ?? 0}-${maximumRv ? maximumRv : 100})%`}
                placement="right"
              >
                <Input
                  theme={theme}
                  fullWidth
                  type="text"
                  value={rv_value}
                  onChange={(e) =>
                    setState({ ..._state, rv_value: Number(Math.max(0, Math.min(100, Number(e)))) })
                  }
                  masking
                  maskNumeric
                  maskDecimalScale={2}
                  endAdornment="%"
                  disabled={
                    Boolean(state?.submission_tier) ||
                    (state.order?.order_submissions?.[0]?.decision?.stipulations &&
                      !isFinancedAmount) ||
                    !canUserEdit
                  }
                  error={doesErrorExists}
                />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Calculation: FC<{
  comparison: string
  vin: string | undefined
  value: string | number | Object
  rowIndex: number
  colIndex: number
  selected: boolean
}> = ({ comparison, vin, value, rowIndex, colIndex, selected }) => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()
  const {
    data: calculations,
    isLoading: calcReqLoading,
    error: calcReqError
  } = useGetPricingCalculation(vin ?? '', rowIndex, colIndex, comparison)
  const { states } = useStoreContext()

  const defaultCurrency = states?.dealDesk?.defaultCurrency?.symbol
  const canUserEdit = useEditPermissionHook()
  const _value = formatNumber(typeof value === 'object' ? value?.monthly_payment : value)
  const selectedOnSelection =
    Object.keys(state.selection ?? {}).length > 0
      ? state.selection.finance_type === state.finance_type &&
      state.selection.vehicleForComparison === state.vehiclesForComparison &&
      state.selection.row === rowIndex &&
      state.selection.col === colIndex
      : false
  const _selected = selected ? selected : selectedOnSelection

  useEffect(() => {
    if (Object.keys(calculations ?? {}).length > 0) {
      dispatch({
        type: DeskingActionTypes.UPDATE_CALCULATION_GRID_CELL,
        payload: {
          vinCGC: vin as string,
          rowIndexCGC: rowIndex,
          colIndexCGC: colIndex,
          vehicleForComparisonCGC: comparison,
          finance_typeCGC: state.finance_type,
          valueCGC: calculations
        }
      })
      if (selectedOnSelection) {
        dispatch({
          type: DeskingActionTypes.UPDATE_SELECTION_DATA,
          payload: {
            vinUSD: vin as string,
            rowIndexUSD: rowIndex,
            colIndexUSD: colIndex
          }
        })
      }
    }
  }, [calculations])

  return (
    <>
      {typeof value === 'object' ? (
        <Button
          theme={theme}
          title={`${defaultCurrency}${_value}`}
          defaultBtn
          disabled={!(typeof value === 'object') || !canUserEdit}
          className={`amount grey-bg flc-li-selfStretch-col ${_selected ? 'selected' : ''}`}
          text={
            calcReqLoading ? (
              <Skeleton
                theme={theme}
                variant="text"
                width={'100%'}
                height={'4vh'}
                sx={{ borderRadius: '4vh' }}
              />
            ) : (
              <>
                <span>{defaultCurrency}</span>
                {_value}
              </>
            )
          }
          onClick={() => {
            if (typeof value === 'object' && !_selected) {
              dispatch({
                type: DeskingActionTypes.UPDATE_SELECTION_DATA,
                payload: {
                  vinUSD: vin as string,
                  rowIndexUSD: rowIndex,
                  colIndexUSD: colIndex
                }
              })
            }
          }}
        />
      ) : (
        <Box theme={theme} className="flc-li-selfStretch-col">
          <Tooltip theme={theme} title={'No Offer Found!'}>
            <Button
              theme={theme}
              title={`${defaultCurrency}${_value}`}
              defaultBtn
              disabled={!(typeof value === 'object')}
              className={`amount grey-bg ${_selected ? 'selected' : ''}`}
              text={
                calcReqLoading ? (
                  <Skeleton
                    theme={theme}
                    variant="text"
                    width={'100%'}
                    height={'4vh'}
                    sx={{ borderRadius: '4vh' }}
                  />
                ) : (
                  <>
                    <span>{defaultCurrency}</span>
                    {_value}
                  </>
                )
              }
              onClick={() => {
                if (typeof value === 'object' && !_selected) {
                  dispatch({
                    type: DeskingActionTypes.UPDATE_SELECTION_DATA,
                    payload: {
                      vinUSD: vin as string,
                      rowIndexUSD: rowIndex,
                      colIndexUSD: colIndex
                    }
                  })
                }
              }}
            />
          </Tooltip>
        </Box>
      )}
    </>
  )
}

export const CalculationBox: FC<{
  comparison: string
  vin: string | undefined
  value: string | number | Object
  rowIndex: number
  colIndex: number
  selected?: boolean
}> = ({ comparison, vin, value, rowIndex, colIndex, selected = false }) => {
  return (
    <Calculation
      comparison={comparison}
      vin={vin}
      value={value}
      rowIndex={rowIndex}
      colIndex={colIndex}
      selected={selected}
    />
  )
}

export const SellingPrice: FC<{
  vin: string
}> = ({ vin }) => {
  const theme = useTheme()
  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const { vehiclesData, order, isVehicleSelectedAndOrderSaved } = state
  const vehicleData = vehiclesData.get(vin)
  const doesVehicleDataExist = Object.keys(vehicleData ?? {}).length > 0
  const sellingPrice =
    isVehicleSelectedAndOrderSaved || order?.order_asset?.unit_price
      ? order?.order_asset?.unit_price
      : vehicleData?.vehicle?.selling_price ?? vehicleData?.vehicle?.internet_price ?? '0'

  const [debouncedState, setState, _state] = useDebouncedState(sellingPrice)
  const canUserEdit = useEditPermissionHook()
  useEffect(() => {
    if (doesVehicleDataExist && Number(sellingPrice) !== Number(debouncedState)) {
      dispatch({
        type: DeskingActionTypes.UPDATE_SELLING_PRICE,
        payload: {
          vinUSP: vin as string,
          valueUSP: Number(debouncedState ?? sellingPrice)
        }
      })
    }
  }, [debouncedState])

  const isFinancedAmount: boolean =
    state.order?.order_submissions?.[0]?.decision?.stipulations?.some(
      (item: IStipulationResponse) => item?.stipulation_code === StipCodes.FINANCED_AMOUNT
    )

  return state.isProgramLoading ? (
    <VehicleLoader width={'100%'} />
  ) : (
    <Input
      theme={theme}
      type="text"
      fullWidth
      startAdornment={defaultCurrency?.symbol}
      value={_state}
      masking
      maskNumeric
      maskDecimalScale={2}
      onChange={(e) => setState(Number(e))}
      disabled={
        Boolean(state?.submission_tier) ||
        (state.order?.order_submissions?.[0]?.decision?.stipulations && !isFinancedAmount) ||
        !canUserEdit
      }
    />
  )
}

const renderRow = (row: any[], rowIndex: number, vin: string | undefined, theme: Theme) => {
  const firstItem = row[0]

  if (firstItem === 'Financed Amount' || firstItem === 'LTV%') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li flc-footer" sx={{ m: '0 !important' }}>
        <Box theme={theme} className="flc-li-left-col">
          <Box theme={theme} className="flc-labels-wrap">
            <Box theme={theme} className="label-row">
              <Label name={firstItem} />
            </Box>
          </Box>
        </Box>
        {row.slice(1).map((value, index) => (
          <Box key={index} theme={theme} className="flc-li-selfStretch-col border-bottom">
            <Value value={value} isCurrency={firstItem !== 'LTV%'} />
          </Box>
        ))}
      </Box>
    )
  }

  if (typeof firstItem === 'string') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li">
        <Box theme={theme} className="flc-li-left-col">
          <Box theme={theme} className="flc-labels-wrap">
            <Box theme={theme} className="label-row">
              <Label name={firstItem} />
            </Box>
          </Box>
        </Box>
        {row.slice(1).map((value, colIndex) => (
          <Box key={colIndex} theme={theme} className="flc-li-selfStretch-col">
            <DownPayment vin={vin} rowIndex={rowIndex} colIndex={colIndex + 1} value={value} />
          </Box>
        ))}
      </Box>
    )
  }

  if (typeof firstItem === 'object') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li">
        <QuoteParam vin={vin} rowIndex={rowIndex} colIndex={0} value={firstItem} />
        {row.slice(1).map((value, colIndex) => (
          <CalculationBox
            key={`${VehiclesForComparisonTypes.SINGLE}-${rowIndex}-${colIndex}`}
            comparison={VehiclesForComparisonTypes.SINGLE}
            vin={vin}
            value={value}
            rowIndex={rowIndex}
            colIndex={colIndex + 1}
          />
        ))}
      </Box>
    )
  }
  return null
}

const CalculationGrid: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const { finance_type, vehiclesVins, vehiclesForComparison } = state
  const calculationBoxes = state[vehiclesForComparison][finance_type].calculationsBoxes
  const vin = vehiclesVins[0]

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <Box theme={theme} className="flc-ul">
          {/********************** Add Vehicle **********************/}
          <Box theme={theme} className="flc-li">
            <AddVehicle vin={vin} index={0} />
          </Box>
          {/********************** Header **********************/}
          <Box theme={theme} className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'Selling Price'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <SellingPrice vin={vin} />
            </Box>
          </Box>
          {calculationBoxes.map((row: any[], rowIndex: number) =>
            renderRow(row, rowIndex, vin, theme)
          )}
        </Box>
      </Box>
    </>
  )
}

export default CalculationGrid
