import "./root.component.css";
import { unityTheme, Snackbar, Box } from "@ntpkunity/controls";
import {
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from "@mui/material/styles";
import {
  Alert,
  Slide,
  SnackbarCloseReason,
  StyledEngineProvider,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Router from "router";
import { QueryClientProvider, QueryClient, useQueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SetupsStoreProvider } from "Stores/SetupStore/Store";
import StoreProvider, {
  useStoreContext,
} from "Stores/SetupStore/Store/storeContext";
import { ModalProvider } from "react-modal-hook";
import { DarkTheme, IndexTheme } from "shared/styles/theme";
import { CustomTheme } from "shared/styles/customTheme";
import { useState, useEffect, useContext } from "react";
import { ThemeContext, DealerProvider, DealerContext } from "context";
import {
  LoginValidatorComponent,
  UMSConfigurationComponent,
} from "@ntpkunity/controls-ums";
import { QueryKeys } from "Enums";
import {
  UseQuery_getCompanyByTenantId,
  UseQuery_getThemeBuilderbyEmail
} from "services";
import { setFavicon, setLogo, setStyleSheet } from "favicon";
import { IThemeBuilder } from "Interface";

export default function Root(props) {
  document.title = "Index";
  const [ready, setReady] = useState(false);
  const onReady = () => {
    if (!ready) setReady(true);
  };
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });
  return (
    <LoginValidatorComponent onReady={onReady} originated_uri={window.location.href}>
      {ready && (
        <UMSConfigurationComponent>
          <StoreProvider>
            <QueryClientProvider client={queryClient}>
              <SetupsStoreProvider>
                <DealerProvider>
                  <App {...props} />
                </DealerProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </SetupsStoreProvider>
            </QueryClientProvider>
          </StoreProvider>
        </UMSConfigurationComponent>
      )}
    </LoginValidatorComponent>
  );
}

const App = (props) => {
  const theme = useTheme();
  const { states, actions } = useStoreContext();
  const { toastData } = states;
  const onToastClose = (
    event?: React.SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason !== "clickaway") {
      actions.setToast({ toastMessage: "", toastState: false });
    }
  };
  const [selectedTheme, setSelectedTheme] = useState(
    localStorage.getItem("selectedTheme") || "light"
  );
  const [tenantId, setTenantId] = useState<number>(null);
  const [userEmail, setUserEmail] = useState<string>(null);
  const [userRole, setUserRole] = useState<string>(null);

  useEffect(() => {
    localStorage.setItem("selectedTheme", selectedTheme);
  }, [selectedTheme]);
  const toggleTheme = () => {
    setSelectedTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  const queryClient = useQueryClient();

  UseQuery_getCompanyByTenantId(tenantId);
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );
  const { dealerData } = useContext(DealerContext);

  useEffect(() => {
    if (localStorage.getItem("settings") != null) {
      var access_token_local = JSON.parse(localStorage.getItem("settings"));
      localStorage.setItem("api-key", access_token_local?.access_token);
      setTenantId(access_token_local?.tenant_id);
      if (
        access_token_local?.role?.name == "Index Dealer Admin User" ||
        access_token_local?.role?.name == "Broker Dealer Admin" ||
        access_token_local?.role?.name == "Dealer Admin"
      ) {
        if (dealerData?.[0]?.email) {
          setUserEmail(dealerData?.[0]?.email);
        }
      }
    }
  }, [dealerData]);

  const { data } = UseQuery_getThemeBuilderbyEmail(userEmail);
  const ThemeBuilderData: IThemeBuilder = data;

  useEffect(() => {
    if (companyProfile) {
      localStorage.setItem("company_id", companyProfile?.id);
      if (
        JSON.parse(localStorage.getItem("settings"))?.role?.name == "Admin" &&
        companyProfile
      ) {
        setUserEmail(companyProfile?.email);
      }
    }
  }, [companyProfile]);

  useEffect(() => {
    if (ThemeBuilderData) {
      setStyleSheet(ThemeBuilderData.stylesheet);
      setFavicon(ThemeBuilderData.favicon?.location);
      setLogo(ThemeBuilderData?.logo?.location);
    }
  }, [ThemeBuilderData]);

  const history = createBrowserHistory({ window });
  return (
    <>
      {/* default Toast Message Length is 3000, we can change when dispatching Toast */}
      {/* <Snackbar
        theme={unityTheme}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={toastData?.toastLength ? toastData.toastLength : 5000}
        variant={toastData?.variant}
        open={toastData?.toastState ? toastData?.toastState : false}
        onClose={onToastClose}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      /> */}

      <StyledEngineProvider injectFirst>
        <ThemeContext.Provider value={{ selectedTheme, toggleTheme }}>
          <MuiThemeProvider
            theme={selectedTheme == "dark" ? DarkTheme : IndexTheme}
          >
            <CssBaseline />
            <CustomTheme className="custom-theme" theme={theme}>
              <Box
                theme={theme}
                className={selectedTheme == "dark" ? "dark-mode" : ""}
              >
                <Snackbar
                  theme={unityTheme}
                  action={toastData?.showIcon ? {} : null}
                  autoHideDuration={
                    toastData?.showIcon
                      ? null
                      : toastData?.toastLength
                        ? toastData?.toastLength
                        : 5000
                  }
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  variant={toastData?.variant}
                  open={toastData?.toastState ? toastData?.toastState : false}
                  onClose={onToastClose}
                  message={
                    toastData?.toastMessage ? toastData?.toastMessage : ""
                  }
                  TransitionComponent={(props) => (
                    <Slide {...props} direction="up" />
                  )}
                />
                <ModalProvider>
                  <HistoryRouter history={history}>
                    <Router />
                  </HistoryRouter>
                </ModalProvider>
              </Box>
            </CustomTheme>
          </MuiThemeProvider>
        </ThemeContext.Provider>
      </StyledEngineProvider>
    </>
  );
};