import React, { FC, useState, useEffect, useRef, useContext } from "react";
import {
  ClickAwayListener,
  Link,
  TablePagination,
  useTheme,
} from "@mui/material";
import { LayoutWithSideNav } from "layout";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Icon,
  DataTable,
  Tooltip,
  Menu,
  Input,
  Typography,
  Grid,
  Button,
  Box,
  Switch,
  ImageViewer,
  PersistentDrawer,
  Checkbox,
  Textarea,
} from "@ntpkunity/controls";
import {
  DrawerWrap,
  PageHeader,
  DataTableWrap,
  ConfirmationDialog,
  PaginationWrap,
  ToolTipDropdown,
} from "@sharedComponents";

import {
  useMutation_BulkAddInventory,
  useGetInventoryFilter,
  useUpdateVehicleByVin,
  useUpdateVehicleDetailByVin,
  useAddNewVehcile,
  useBulkUpdateVehicle,
} from "services/inventory-management.service";
import {
  useMutation_CheckS3Credentials,
  useGetStationeryByTypeAndEvent,
  useSendInventoryEmailReferral,
  useGetCompanyIntegration,
} from "services";

import { validation } from "shared/helper/methods";
import {
  DEFAULT_REFERRAL_EMAIL_DESCRIPTION,
  EMAIL_REGEX,
  INVENTORY_EXCEL_FILE_ENDPOINT,
} from "shared/config/constants";
import {
  Lisiting_Status,
  Daily_Update,
  Status,
  QueryKeys,
  ActionOptions,
  DialogMessages,
  ActionTypes,
  ValidationMessages,
  Provider,
} from "Enums";

import { IDealer, IInventory, IStationery, IVehicle } from "Interface";
import { Controller, useForm } from "react-hook-form";
import { useStoreContext } from "Stores/SetupStore/Store";
import ChevronDown from "shared/assets/images/chevron-down";
import { AttachedOptions, InventoryPopup } from "modules/inventory-options";
import { useQueryClient } from "react-query";
import { DealerContext } from "context";
import { AddNewVehicle } from "../components/AddNewVehicle/AddNewVehicle";
interface IFormInputs {
  id?: number;
  vin: string;
  year: string;
  make: string;
  model: string;
  model_code: string;
  trim_description: string;
  internet_price: number;
  type: string;
  exterior_color_description: string;
  interior_color_description: string;
  transmission_type: string;
  doors: number;
  engine_cylinders: number;
  engine_description: string;
  fuel_type: string;
  drive_train: string;
  dealer_code: string;
  mileage: string;
  dealer_id: string;
  container_file_name: string;
  listing_status: string;
  operator: string;
  selling_price: string;
}
interface IFilters {
  vehice_year?: string;
  vehice_make?: string;
  vehice_model?: string;
  vehice_trim?: string;
  stockNumber?: string;
  vin?: string;
  listingStatus?: string;
  status?: string;
  assetCondition?: string;
  dealerOption?: string;
  mileage?: number;
  msrp?: number;
  internetPrice?: number;
  dailyUpdate?: boolean | null;
}

interface IREFERRALFORMINPUTS {
  fName?: string;
  lName?: string;
  email: string;
  description?: string;
}

const InventoryManagement: FC = () => {
  const theme = useTheme();
  const { states, actions } = useStoreContext();
  const queryClient = useQueryClient();
  const { dealerData, dealer_code } = useContext(DealerContext);
  const [popUpState, setPopUpState] = useState(false);
  const [popUpInviteDrawer, setPopUpInviteDrawer] = useState(false);
  const [popUpOpenState, setPopUpOpenState] = useState("");
  const [editableCell, setEditableCell] = useState<number>(-1);
  const [editedInternetPrice, setEditedInternetPrice] = useState(-1);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [images, setImages] = useState([]);
  const [visibility, setVisibility] = useState(false);
  const [index, setIndex] = useState(0);
  const [route, setRoute] = useState();
  const [options, setOptions] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [columnFilters, setColumnFilters] = useState(
    `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
  );
  const [searchFilters, setSearchFilters] = useState<IFilters>({});
  const [referralVehicle, setReferralVehicle] = useState<IInventory>();
  const { mutate: AddInventory } = useMutation_BulkAddInventory();
  const { mutate: addNewVehcile } = useAddNewVehcile();
  const { mutate: sendEmailReferral, isLoading: referralSendLoading } =
    useSendInventoryEmailReferral();
  const { mutate: bulkUpdateVehicle } = useBulkUpdateVehicle();
  const { mutate: updateVehicleDetail } = useUpdateVehicleDetailByVin();
  const { mutate: getCompanyIntegration, data: companyIntegration } =
    useGetCompanyIntegration();

  const { mutate: getStationeryData, isLoading: stationeryLoading } =
    useGetStationeryByTypeAndEvent();
  const { mutate: updateVehicle } = useUpdateVehicleByVin();
  const { mutate: checkS3Credentials, data: s3Response } =
    useMutation_CheckS3Credentials();
  const [openAddMoreInventoryDialog, setOpenAddMoreInventoryDialog] =
    useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [dealer_id, setDealerId] = useState("");
  const [warningPopupState, setWarningPopupState] = useState<boolean>(false);
  const [modalClose, setModalClose] = useState<boolean>(false);
  let statusIcon;

  const { data } = useGetInventoryFilter(columnFilters);
  const inventoryFilterData = data;
  const allCurrencies: any = queryClient.getQueryData(
    QueryKeys.GET_ALL_CURRENCIES
  );
  const companyProfile: any = queryClient.getQueryData(
    QueryKeys.GET_COMPANY_PROFILE
  );

  const allDealers: Array<IDealer> = queryClient.getQueryData(
    QueryKeys.GET_ALL_DEALERS
  );

  useEffect(() => {
    if (dealerData) {
      setDealerId(
        dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.id
      );
    }
    setModelName([]);
  }, [dealer_code, dealerData]);
  const handleshow = () => {
    setPopUpState(true);
    setPopUpOpenState(ActionOptions.ADD_VEHICLE);
  };
  const handleshow1 = () => {
    if (enableBulkActions) {
      setPopUpState(true);
      setModelName(
        Array.from(
          new Set(
            inventoryFilterData?.result
              ?.filter((vehicle) => selected.includes(vehicle?.vin))
              .map((data) => data?.vehicle?.model)
          )
        )
      );
      setPopUpOpenState(ActionOptions.BULK_UPDATE);
    } else {
      actions.setToast({
        toastMessage:
          "You have not selected any record for bulk operation. Please select to proceed.",
        toastState: true,
        variant: "error",
      });
    }
  };
  const handleShowReferralDrawer = (vehicle: IInventory) => {
    referralForm.reset({} as IREFERRALFORMINPUTS);
    setReferralVehicle(vehicle);
    setPopUpInviteDrawer(true);
  };
  const [defaultCurrency, setDefaultCurrency] = useState<string>("");
  useEffect(() => {
    checkS3Credentials(JSON.parse(localStorage.getItem("settings"))?.tenant_id);
  }, []);
  useEffect(() => {
    const filteredCurrency = allCurrencies?.filter(
      (currency) => currency.id === companyProfile?.default_currency
    );
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0
        ? filteredCurrency[0].symbol
        : ""
    );
  }, [companyProfile]);

  useEffect(() => {
    getCompanyIntegration("inventory_management");
  }, []);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };
  useEffect(() => {
    companyIntegration?.data?.providers?.forEach((provider) => {
      setRoute(companyIntegration?.data?.route);
      return;
    });
  }, [companyIntegration]);

  useEffect(() => {
    setOptions(
      route
        ? tableOptions.filter(
            (option) => option.optionkey !== ActionOptions.UPLOAD_FILE
          )
        : tableOptions
    );
  }, [route, dealer_code]);
  const [assetMakeId, setAssetMakeId] = useState(null);
  const [assetModelId, setAssetModelId] = useState(null);
  const [assetTrimId, setAssetTrimId] = useState(null);
  const [assestTrimCode, setAssetTrimCode] = useState(null);
  const [sellingPrice, setSellingPrice] = useState("");
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };
  const form = useForm<any>({ mode: "all" });
  const referralForm = useForm<any>({ mode: "all" });

  const {
    formState: { errors },
  } = referralForm;
  const { getValues, reset, setValue, handleSubmit, control } = form;
  const onClose = () => {
    let children = document.querySelectorAll(".drawer-header .pinned");
    if (children.length == 0) {
      setAssetMakeId(null);
      setAssetModelId(null);
      setAssetTrimId(null);
      setPopUpState(false);
      reset();
      reset({ ...getValues() });
      reset(data);
      setValue("listing_status", "");
      setValue("operator", "");
      setValue("selling_price", "");
      setValue("customize_selling_price", "");
    }
  };
  const handleSendReferral = () => {
    if (stationeryLoading || referralSendLoading) return;

    getStationeryData(
      { type: "Email", event: "Inventory Customer Referral" },
      {
        onSuccess: (stationeryData: IStationery[]) => {
          if (
            !stationeryData ||
            stationeryData?.length < 1 ||
            stationeryData[0]?.templates?.length < 1
          ) {
            actions.setToast({
              toastMessage: ValidationMessages.REFERRAL_TEMPLATE_NOT_CONFIGURED,
              toastState: true,
              variant: "error",
            });

            return;
          }

          const template_key =
            stationeryData[0] &&
            stationeryData[0]?.templates[0] &&
            stationeryData[0]?.templates[0]?.template_name;
          const referralDescription = referralForm.getValues("description");
          const vehicle = referralVehicle?.vehicle;

          const payload = {
            source_email: companyProfile?.email_status == "Verified" ? companyProfile?.correspondence_email : undefined,
            recipient_emails: [referralForm.getValues("email")],
            subject: `Your ${
              vehicle?.make?.toLowerCase() === "mini" ? "MINI" : "vehicle"
            } is just a few clicks away!`,
            template_key: template_key,
            data: {
              co_applicant_first_name:
                referralForm.getValues("fName") || undefined,
              co_applicant_last_name:
                referralForm.getValues("lName") || undefined,
              default_text: referralDescription
                ? referralDescription
                : DEFAULT_REFERRAL_EMAIL_DESCRIPTION,
              dealer_name: allDealers?.find(
                (x) => x?.dealer_code === dealer_code
              )?.dealer_name,
              vin: vehicle?.vin,
              year: vehicle?.year || "",
              make_name: vehicle?.make || "",
              model_name: `${vehicle?.engine || ""} ${vehicle?.model || ""}`,
              trim_name: vehicle?.trim_description || "",
              asset_referral_link: `${process.env.DRIFT_BASE_URL}/${companyProfile?.slug}/vehicle-detail/${vehicle?.vin}`,
              asset_image: vehicle?.photo_urls?.[0]?.location,
              footer_year: new Date()?.getFullYear(),
            },
          };

          sendEmailReferral(payload, {
            onSuccess() {
              handleCloseInviteDrawer();
              actions.setToast({
                toastMessage: "Email Sent Successfully",
                toastState: true,
              });
            },
            onError(error: any) {
              setPopUpState(false);
              actions.setToast({
                toastMessage: error?.detail?.msg?.toString(),
                toastState: true,
                variant: "error",
              });
            },
          });
        },
      }
    );
  };

  const handleUploadedPhotos = () => {
    if (popUpOpenState === ActionOptions.ADD_VEHICLE) {
      const photos = getValues("image_url").split(",");
      if (photos) {
        return photos?.map((photo) => {
          return {
            name: "",
            location: photo,
          };
        });
      }
    }
  };
  const handleSaveVehicle = () => {
    const data = {
      vin: getValues("vin"),
      stock_number: getValues("stock_number"),
      year: getValues("year"),
      make: getValues("make.label"),
      model: getValues("model.label"),
      model_code: getValues("model_code"),
      trim_description: getValues("trim.label"),
      trim_code: getValues("assest_trim_code"),
      internet_price: getValues("selling_price"),
      msrp: getValues("msrp"),
      type: getValues("asset_condition"),
      exterior_color_description: getValues("exterior_color_description"),
      interior_color_description: getValues("interior_color_description"),
      transmission_type: getValues("trasnmission_type"),
      body_type: getValues("body_type"),
      body_style: getValues("body_style"),
      doors: getValues("doors"),
      engine_cylinders: getValues("engine_cylinders"),
      engine_description: getValues("engine_description"),
      // "fuel_type": getValues(""),
      drive_train: getValues(""),
      dealer_code: dealer_code,
      mileage: getValues("mileage"),
      dealer_id: dealer_id,
      container_file_name: "front_end",
      photo_urls: handleUploadedPhotos(),
    };
    const bulkUpdateData = {
      dealer_code: dealer_code,
      ...(route != Provider.Hubex && getValues("listing_status") !== "" && {
        listing_status: getValues("listing_status"),
      }),
      ...((getValues("attached_options") !== undefined ||
        getValues("attached_options") == "") && {
        option: getValues("attached_options"),
      }),
      ...(getValues("operator") !== undefined && {
        operator: getValues("operator"),
      }),
      ...(getValues("selling_price") !== undefined &&
        getValues("selling_price") !== "" && {
          selling_price: getValues("selling_price"),
        }),
      selected,
    };
    if (popUpOpenState === ActionOptions.ADD_VEHICLE) {
      addNewVehcile(data, {
        onSuccess() {
          onClose();
          form.reset({} as IFormInputs);
          actions.setToast({
            toastMessage: "Record Added Successfully",
            toastState: true,
          });
        },
        onError(error: any) {
          setPopUpState(false);
          actions.setToast({
            toastMessage: error?.detail ?? "VIN Already Exists",
            toastState: true,
            variant: "error",
          });
        },
      });
    } else {
      bulkUpdateVehicle(bulkUpdateData, {
        onSuccess() {
          setSellingPrice("");
          onClose();
          setSelected([]);
          reset();
          actions.setToast({
            toastMessage: "Record Updated Successfully",
            toastState: true,
          });
        },
        onError(error: any) {
          setPopUpState(false);
          actions.setToast({
            toastMessage: error?.response?.data?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
        },
      });
    }
  };
  const updateVehicleStatuses = async (
    vehicleDetail: any,
    valueIdentifier: string
  ) => {
    switch (valueIdentifier) {
      case "dailyUpdate":
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
            queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          },
        });
        return;
      case "listingStatus":
        await updateVehicleDetail(vehicleDetail, {
          onSuccess() {
            actions.setToast({
              toastMessage: "Record Updated Successfully",
              toastState: true,
            });
            queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          },
        });
        return;
    }
  };
  const updateVehicleInternetPrice = async (id: number, vehicle: IVehicle) => {
    await updateVehicle(
      { vin: vehicle.vin, vehicle },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: "Record Updated Successfully",
            toastState: true,
          });
          queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
        },
      }
    );
    return;
  };

  const handleImage = (imagesData) => {
    const image_url = imagesData?.map((image) => {
      return image?.location;
    });

    setImages(image_url);
    setVisibility(true);
  };

  const handleClose = () => setVisibility(false);
  const handleCloseInviteDrawer = () => {
    referralForm?.reset({} as IREFERRALFORMINPUTS);
    setReferralVehicle(null);
    setPopUpInviteDrawer(false);
  };
  useEffect(() => {
    if (dealer_code) {
      setColumnFilters(
        `dealer_code=${dealer_code}&page_number=${pageNumber}&page_size=${pageSize}`
      );
    }
    let children = document.querySelectorAll(".drawer-header .pinned");
    children.forEach((child) => {
      child.classList.remove("pinned");
    });

    onClose();
    setSelected([]);
  }, [dealer_code]);
  const [openOptionDialog, setopenOptionDialog] = React.useState(false);
  const [vehicleObject, setvehicleObject] = React.useState<any>(null);
  const handleClickProductOpen = () => {
    setopenOptionDialog(true);
  };
  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `dealer_code=${dealer_code}&page_number=${pgNo}&page_size=${pgSize}`;
    if (
      getValues("vehice_year")?.trimStart() &&
      getValues("vehice_year")?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehice_year=${getValues("vehice_year")}`
      );
    }
    if (
      getValues("vehice_make")?.trimStart() &&
      getValues("vehice_make")?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehice_make=${getValues("vehice_make")}`
      );
    }
    if (
      getValues("vehice_model")?.trimStart() &&
      getValues("vehice_model")?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehice_model=${getValues("vehice_model")}`
      );
    }
    if (
      getValues("vehice_trim")?.trimStart() &&
      getValues("vehice_trim")?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehice_trim=${getValues("vehice_trim")}`
      );
    }
    if (
      searchFilters?.stockNumber?.trimStart() &&
      searchFilters?.stockNumber?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&stock_number=${searchFilters.stockNumber}`
      );
    }
    if (
      searchFilters?.vin?.trimStart() &&
      searchFilters?.vin?.trimStart() != ""
    ) {
      query_string = query_string.concat(`&vin=${searchFilters.vin}`);
    }
    if (searchFilters?.mileage) {
      query_string = query_string.concat(`&mileage=${searchFilters.mileage}`);
    }
    if (searchFilters?.msrp) {
      query_string = query_string.concat(`&msrp=${searchFilters.msrp}`);
    }
    if (searchFilters?.internetPrice) {
      query_string = query_string.concat(
        `&internet_price=${searchFilters.internetPrice}`
      );
    }
    if (searchFilters.dailyUpdate != null) {
      query_string = query_string.concat(
        `&daily_update=${searchFilters.dailyUpdate}`
      );
    }
    if (
      searchFilters?.listingStatus?.trimStart() != null &&
      searchFilters?.listingStatus?.trimStart() != ""
    ) {
      let listingStatus = "";
      if (
        "listed".startsWith(
          searchFilters?.listingStatus
            .trimStart()
            .toLowerCase()
            .slice(0, Math.max("listed".length - 1, 1))
        )
      ) {
        listingStatus = "listed";
      } else if (
        "not listed".startsWith(
          searchFilters?.listingStatus
            .trimStart()
            .toLowerCase()
            .slice(0, Math.max("not listed".length - 1, 1))
        )
      ) {
        listingStatus = "not listed";
      } else if (searchFilters?.listingStatus.trimStart() !== "") {
        listingStatus = "none";
      }
      query_string = query_string.concat(`&listing_status=${listingStatus}`);
    }
    if (
      searchFilters?.status?.trimStart() != null &&
      searchFilters?.status?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&vehicle_status=${searchFilters.status}`
      );
    }
    if (
      searchFilters?.assetCondition?.trimStart() != null &&
      searchFilters?.assetCondition?.trimStart() != ""
    ) {
      query_string = query_string.concat(
        `&multiple_asset_condition=${searchFilters.assetCondition}`
      );
    }
    if (searchFilters.dealerOption) {
      query_string = query_string.concat(
        `&dealer_option=${searchFilters.dealerOption}`
      );
    }
    return query_string;
  };
  const getStatusIcon = (status) => {
    switch (status) {
      case Status.Available:
        statusIcon = "ind-success";
        break;
      case Status.Inquiry:
        statusIcon = "ind-danger";
        break;
      case Status.InContarct:
        statusIcon = "ind-contract";
        break;
      case Status.NotAvailable:
        statusIcon = "ind-danger";
        break;
      default:
        statusIcon = "ind-success";
    }
  };

  const handleSelection = (event, key, value) => {
    if (key == ActionOptions.UPLOAD_FILE) {
      if (!s3Response?.data) {
        return;
      }
      if (
        !dealerData?.find((obj) => obj?.dealer_code === dealer_code)?.is_active
      ) {
        return;
      }
      if (inventoryFilterData?.totalResults >= 0) {
        setWarningPopupState(true);
      } else {
        hiddenFileInput.current.click();
      }
    }
    if (key == ActionOptions.DOWNLOAD_INVENTORY_FILE) {
      window.location.href = `${
        process.env.DMS_BUCKET_BASE_URL + INVENTORY_EXCEL_FILE_ENDPOINT
      }`;
      actions.setToast({
        toastMessage: "File Downloading, Please wait.",
        toastState: true,
      });
    }
  };
  const tableOptions = [
    {
      optionText: <>{ActionOptions.DOWNLOAD_INVENTORY_FILE}</>,
      optionkey: ActionOptions.DOWNLOAD_INVENTORY_FILE,
    },
    {
      optionText: <>{ActionOptions.UPLOAD_FILE}</>,
      optionkey: ActionOptions.UPLOAD_FILE,
    },
  ];

  const handleChange = (event) => {
    setOpenAddMoreInventoryDialog(true);
    setInventoryData([]);
    const obj: any = {
      tenant_id: JSON.parse(localStorage.getItem("settings"))?.tenant_id,
    };
    const body = new FormData();
    body.append("files", event.target.files[0]);
    obj.files = body;
    AddInventory(
      { dealer_code: dealer_code, obj },
      {
        onSuccess: (response) => {
          setInventoryData(response.data);
          queryClient.invalidateQueries(QueryKeys.GET_ALL_INVENTORY_FILTER);
          setModalClose(true);
        },
      }
    );
    event.target.value = null;
  };
  const hiddenFileInput = useRef(null);
  const confirmationText =
    inventoryFilterData?.totalResults > 0
      ? `
  - The file you are uploading will override the existing records.
  - The image URLs provided in the file should be publicly accessible otherwise the images will not be displayed.

  Are you sure you want to continue?
`
      : `
- The image URLs provided in the file should be publicly accessible otherwise the images will not be displayed.

Are you sure you want to continue?
`;
  const handleBtn = (e) => {
    hiddenFileInput.current.click();
  };
  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };
  const handleRest = () => {
    setValue("vehice_year", "");
    setValue("vehice_make", "");
    setValue("vehice_model", "");
    setValue("vehice_trim", "");
    setOpenFilter(!openFilter);
    handleFilter();
  };
  const [selected, setSelected] = useState<any[]>([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = inventoryFilterData?.result?.map((n: any) => n.vin);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const numSelected = selected?.length;
  const rowCount = inventoryFilterData?.result?.length;

  const enableBulkActions = numSelected > 0;
  const disableBulkActions = numSelected <= 0;
  const selectedSomeTransactions = numSelected > 0 && numSelected < rowCount;
  const selectedAllTransactions = rowCount > 0 && numSelected === rowCount;
  const isSelected = (vin: string): boolean => selected?.includes(vin);
  const handleClick = (event, transactionId, model): void => {
    if (!selected.includes(transactionId)) {
      setSelected((prevSelected) => [...prevSelected, transactionId]);
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((vin) => vin !== transactionId)
      );
    }
  };

  useEffect(() => {
    if (popUpOpenState == ActionOptions.BULK_UPDATE && popUpState) {
      setModelName(
        Array.from(
          new Set(
            inventoryFilterData?.result
              ?.filter((vehicle) => selected.includes(vehicle?.vin))
              .map((data) => data?.vehicle?.model)
          )
        )
      );
    }
  }, [selected]);

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenFilter(false);
  };

  const handleTooltipOpen = () => {
    setOpenFilter(true);
  };

  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader className="main-page-header">
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
              <Typography
                data-testId="heading"
                variant="h2"
                component="h2"
                theme={theme}
              >
                Manage Inventory
              </Typography>
            </Grid>
            <Grid
              theme={theme}
              item
              xs={12}
              lg={5}
              sm={12}
              md={6}
              textAlign="right"
              className="action-area"
            >
              <Menu
                theme={theme}
                disablePortal
                options={options?.map((option) => {
                  if (
                    option.optionText.props.children.toString() ===
                      ActionOptions.UPLOAD_FILE &&
                    !s3Response?.data
                  )
                    return {
                      optionText: (
                        <Tooltip
                          title={ActionTypes.STORAGE_PATH_NOT_CONFIGURED}
                          disablePortal
                          theme={theme}
                          placement="left"
                        >
                          {option.optionText}
                        </Tooltip>
                      ),
                      optionkey: option.optionkey,
                      optionValue: {},
                      disabled: true,
                    };
                  else if (
                    option.optionText.props.children.toString() ===
                      ActionOptions.UPLOAD_FILE &&
                    !dealerData?.find((obj) => obj?.dealer_code === dealer_code)
                      ?.is_active
                  ) {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: {},
                      disabled: true,
                    };
                  } else
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: {},
                    };
                })}
                handleOptionClick={handleSelection}
                render={(onMenuSelection) => (
                  <Button
                    theme={theme}
                    secondary
                    iconText={<Icon name="MoreIcon" />}
                    id="programCancel"
                    onClick={onMenuSelection}
                  />
                )}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              />
              <Button
                theme={theme}
                primary
                text="Bulk Update"
                onClick={handleshow1}
              />
              {route != Provider.Hubex && (
                <Button
                  theme={theme}
                  primary
                  text="Add New Vehicle"
                  onClick={handleshow}
                />
              )}
            </Grid>
          </Grid>
        </PageHeader>
        <input
          type="file"
          accept=".xls,.xlsx"
          ref={hiddenFileInput}
          id="InventoryUploadId"
          style={{ display: "none" }}
          onChange={handleChange}
        />

        <DataTableWrap className="table-pagination">
          <Box theme={theme} className="scroll">
            <Box
              className="scroll-hide sh-fixed-cell spr-border"
              theme={theme}
            />
          </Box>
          <DataTable
            theme={theme}
            variant="basic"
            sx={{ mb: 3 }}
            theadChildren={
              <>
                <TableRow>
                  <TableCell className="checkbox-cell">
                    {/* <Checkbox label=""></Checkbox> */}
                    <Checkbox
                      theme={theme}
                      label=""
                      onChange={handleSelectAllClick}
                      checkBoxChecked={selectedAllTransactions}
                      checkBoxIndeterminate={selectedSomeTransactions}
                    ></Checkbox>
                  </TableCell>
                  <TableCell className="img-cell">
                    <Icon name="ImageIcon" />
                  </TableCell>
                  <TableCell
                    id="modelTrimYearHead"
                    sx={{ minWidth: "280px !important" }}
                  >
                    {route != Provider.Hubex
                      ? "Year/Make/Model/Trim"
                      : "Year/Make/Model"}
                  </TableCell>
                  <TableCell id="stockHead">Stock Number</TableCell>
                  <TableCell id="VINHead">VIN</TableCell>
                  {route != Provider.Hubex && (
                    <>
                      <TableCell id="listingStatusHead">
                        Listing Status
                      </TableCell>
                      <TableCell id="statusHead">Status</TableCell>
                    </>
                  )}
                  <TableCell id="assetConditionHead">Asset Condition</TableCell>
                  <TableCell id="optionHead">Add-Ons</TableCell>
                  <TableCell id="mileageHead">Mileage</TableCell>
                  <TableCell id="MSRPHead">MSRP</TableCell>
                  <TableCell id="selingPriceHead">Seling Price</TableCell>
                  {route != Provider.Hubex && (
                    <TableCell id="dailyUpdateHead">Daily Update</TableCell>
                  )}
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
                <TableRow className="filters-row">
                  <TableCell className="checkbox-cell"></TableCell>
                  <TableCell className="img-cell"></TableCell>
                  <TableCell>
                    <Box
                      theme={theme}
                      display={"flex"}
                      gap={"5px"}
                      className="multi-control-wrap"
                    >
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="cal">
                          <ToolTipDropdown
                            theme={theme}
                            onClose={handleTooltipClose}
                            open={openFilter}
                            placement="bottom"
                            disableFocusListener={false}
                            disableHoverListener={false}
                            disableTouchListener={false}
                            slotProps={{
                              popper: {
                                disablePortal: false,
                              },
                            }}
                            title={
                              <>
                                <Controller
                                  name="vehice_year"
                                  control={control}
                                  defaultValue={""}
                                  render={({ field: { onChange, value } }) => (
                                    <Input
                                      id="vehice_year"
                                      theme={theme}
                                      fullWidth={true}
                                      placeholder={"Search..."}
                                      type="number"
                                      dynamic={false}
                                      label="Year"
                                      value={value}
                                      onChange={onChange}
                                      onBlur={(e) => {
                                        handleFilter();
                                      }}
                                    />
                                  )}
                                />
                                <Controller
                                  name="vehice_make"
                                  control={control}
                                  defaultValue={""}
                                  render={({ field: { onChange, value } }) => (
                                    <Input
                                      id="vehice_make"
                                      theme={theme}
                                      fullWidth={true}
                                      placeholder={"Search..."}
                                      type="text"
                                      dynamic={false}
                                      label="Make"
                                      value={value ?? ""}
                                      onChange={onChange}
                                      onBlur={(e) => {
                                        handleFilter();
                                      }}
                                    />
                                  )}
                                />
                                <Controller
                                  name="vehice_model"
                                  control={control}
                                  defaultValue={""}
                                  render={({ field: { onChange, value } }) => (
                                    <Input
                                      id="modelSearch"
                                      theme={theme}
                                      fullWidth={true}
                                      placeholder={"Search..."}
                                      type="text"
                                      dynamic={false}
                                      label="Model"
                                      value={value}
                                      onChange={onChange}
                                      onBlur={(e) => {
                                        handleFilter();
                                      }}
                                    />
                                  )}
                                />
                                {route != Provider.Hubex && (
                                  <Controller
                                    name="vehice_trim"
                                    control={control}
                                    defaultValue={""}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Input
                                        id="trimSearch"
                                        theme={theme}
                                        fullWidth={true}
                                        placeholder={"Search..."}
                                        type="text"
                                        dynamic={false}
                                        label="Trim"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={(e) => {
                                          handleFilter();
                                        }}
                                      />
                                    )}
                                  />
                                )}
                                <Box theme={theme} display={"flex"} gap={1}>
                                  <Button
                                    theme={theme}
                                    secondary
                                    iconText={<Icon name="ResetIcon" />}
                                    onClick={() => {
                                      handleRest();
                                    }}
                                  />
                                  <Button
                                    theme={theme}
                                    primary
                                    fullWidth
                                    text={"Search"}
                                    onClick={() => {
                                      handleFilter();
                                      setOpenFilter(!openFilter);
                                    }}
                                  />
                                </Box>
                              </>
                            }
                          >
                            <Button
                              theme={theme}
                              defaultBtn
                              fullWidth
                              title="Search Year/Make/Model/Trim"
                              className="btn-select-style"
                              onClick={handleTooltipOpen}
                              text={
                                <>
                                  <span className="text-overflow placeholder label">
                                    {getValues("vehice_year") != "" &&
                                      getValues("vehice_year")}{" "}
                                    {getValues("vehice_make") != "" &&
                                      getValues("vehice_make")}{" "}
                                    {getValues("vehice_model") != "" &&
                                      getValues("vehice_model")}{" "}
                                    {getValues("vehice_trim") != "" &&
                                      getValues("vehice_trim")}
                                  </span>
                                </>
                              }
                              endIcon={<Icon name="ChevronDown" />}
                            />
                          </ToolTipDropdown>
                        </div>
                      </ClickAwayListener>
                      <Box theme={theme} className="table-filter">
                        <Button
                          defaultBtn
                          id="makeModelTrimYearFilter"
                          iconText={<Icon name="IconFilter" />}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="stockSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.stockNumber}
                        disabled={route === Provider.Hubex}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            stockNumber: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="stockFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="VINSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.vin}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            vin: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="VINFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  {route != Provider.Hubex && (
                    <>
                      <TableCell>
                        <Box theme={theme} className="table-filter">
                          <Input
                            id="listingStatusSearch"
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            dynamic={false}
                            value={searchFilters.listingStatus}
                            onChange={(e) => {
                              setSearchFilters({
                                ...searchFilters,
                                listingStatus: e,
                              });
                            }}
                            onBlur={(e) => {
                              handleFilter();
                            }}
                          />
                          <Button
                            defaultBtn
                            id="listingStatusFilter"
                            iconText={<Icon name="IconFilter" />}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box theme={theme} className="table-filter">
                          <Input
                            theme={theme}
                            fullWidth={true}
                            placeholder={"Search..."}
                            type="text"
                            dynamic={false}
                            value={searchFilters.status}
                            onChange={(e) => {
                              setSearchFilters({
                                ...searchFilters,
                                status: e.toLowerCase(),
                              });
                            }}
                            onBlur={(e) => {
                              handleFilter();
                            }}
                          ></Input>
                          <Button
                            defaultBtn
                            id="statusFilter"
                            iconText={<Icon name="IconFilter" />}
                          />
                        </Box>
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      {/* <MultiSelect
                        id="assetConditionSearch"
                        theme={theme}
                        label={"Select"}
                        placeholder={"Select"}
                        value={searchFilters.assetCondition || []}
                        items={Object.keys(Vehicle_Type)
                          .filter((value) => isNaN(Number(value)) === false)
                          .map((key: any) => ({
                            value: key,
                            text: Vehicle_Type[key],
                          }))}
                        sxProps={{ m: 0, width: "100%" }}
                        onChange={(e: any) => {
                          setSearchFilters({
                            ...searchFilters,
                            assetCondition: e.target.value,
                          });
                        }}
                        renderValue={() =>
                          searchFilters.assetCondition
                            ?.map((item) => item)
                            .join(", ")
                        }
                      ></MultiSelect> */}

                      <Input
                        id="assetConditionSearch"
                        theme={theme}
                        fullWidth={true}
                        disabled={route === Provider.Hubex}
                        placeholder={"Search..."}
                        type="text"
                        dynamic={false}
                        value={searchFilters.assetCondition}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            assetCondition: e.toLowerCase(),
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      />
                      <Button
                        defaultBtn
                        id="assetConditionFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="optionSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="text"
                        value={searchFilters.dealerOption}
                        disabled={route === Provider.Hubex}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            dealerOption: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="optionFilter"
                        iconText={<Icon name="IconFilter" />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="mileageSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        dynamic={false}
                        value={searchFilters.mileage}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            mileage: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="mileageFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="MSRPSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        dynamic={false}
                        value={searchFilters.msrp}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            msrp: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="MSRPFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        id="internetPriceSearch"
                        theme={theme}
                        fullWidth={true}
                        placeholder={"Search..."}
                        type="number"
                        disabled={route === Provider.Hubex}
                        dynamic={false}
                        value={searchFilters.internetPrice}
                        onChange={(e) => {
                          setSearchFilters({
                            ...searchFilters,
                            internetPrice: e,
                          });
                        }}
                        onBlur={(e) => {
                          handleFilter();
                        }}
                      ></Input>
                      <Button
                        defaultBtn
                        id="internetPriceFilter"
                        iconText={<Icon name="IconFilter" />}
                        onClick={(_e) => {}}
                      />
                    </Box>
                  </TableCell>
                  {route != Provider.Hubex && (
                    <TableCell>
                      <Box theme={theme} className="table-filter">
                        <Input
                          theme={theme}
                          fullWidth={true}
                          placeholder={"Search..."}
                          type="text"
                          dynamic={false}
                          onChange={(e) => {
                            if (e.toLowerCase().trimStart() != "")
                              setSearchFilters({
                                ...searchFilters,
                                dailyUpdate: "enabled".includes(e.toLowerCase())
                                  ? true
                                  : false,
                              });
                            else {
                              setSearchFilters({
                                ...searchFilters,
                                dailyUpdate: null,
                              });
                            }
                          }}
                          onBlur={(e) => {
                            handleFilter();
                          }}
                        />
                        <Button
                          defaultBtn
                          id="dailyUpdateFilter"
                          iconText={<Icon name="IconFilter" />}
                        />
                      </Box>
                    </TableCell>
                  )}
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {inventoryFilterData?.result?.map(
                  (vehicleDetail: IInventory, index: number) => {
                    const isItemSelected = isSelected(vehicleDetail?.vin);
                    return (
                      <TableRow key={index} className="child-tr">
                        <TableCell className="checkbox-cell">
                          {/* <Checkbox label=""></Checkbox> */}
                          <Checkbox
                            theme={theme}
                            label=""
                            checkBoxChecked={isItemSelected}
                            onChange={(event) => {
                              handleClick(
                                event,
                                vehicleDetail?.vin,
                                vehicleDetail?.vehicle?.model
                              );
                            }}
                          ></Checkbox>
                        </TableCell>
                        <TableCell className="img-cell">
                          {(() => {
                            const photoUrls =
                              vehicleDetail?.vehicle?.photo_urls || [];
                            const firstPhotoUrl = photoUrls[0]?.location;

                            if (
                              firstPhotoUrl &&
                              firstPhotoUrl !== "" &&
                              firstPhotoUrl !== "https://" &&
                              firstPhotoUrl !== "images_coming_soon"
                            ) {
                              return (
                                <Link
                                  onClick={() => {
                                    handleImage(
                                      vehicleDetail?.vehicle?.photo_urls
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    className="prd-img"
                                    src={firstPhotoUrl}
                                    alt="Car"
                                  />
                                </Link>
                              );
                            } else {
                              return (
                                <Icon className="no-img" name="ImageIcon" />
                              );
                            }
                          })()}
                        </TableCell>
                        <TableCell className="fixed-width-col">
                          <Tooltip
                            theme={theme}
                            title={
                              <>
                                {vehicleDetail?.vehicle?.year}{" "}
                                {vehicleDetail?.vehicle?.make}{" "}
                                {vehicleDetail?.vehicle?.model}{" "}
                                {vehicleDetail?.vehicle?.trim_description}
                              </>
                            }
                            children={
                              <>
                                <Box theme={theme} className="text-overflow">
                                  {vehicleDetail?.vehicle?.year}{" "}
                                  {vehicleDetail?.vehicle?.make}{" "}
                                  {vehicleDetail?.vehicle?.model}{" "}
                                  {vehicleDetail?.vehicle?.trim_description}
                                </Box>
                              </>
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {vehicleDetail?.vehicle?.stock_number}
                        </TableCell>
                        <TableCell>
                          <Box theme={theme} display="flex" alignItems="center">
                            <Tooltip
                              theme={theme}
                              title={vehicleDetail?.vehicle?.vin}
                              children={<>{vehicleDetail?.vehicle?.vin}</>}
                            />
                          </Box>
                          {/* {vehicleDetail?.vehicle?.vin} */}
                        </TableCell>
                        {route != Provider.Hubex && (
                          <>
                            <TableCell>
                              <Switch
                                theme={theme}
                                varient={"basic"}
                                label={
                                  Lisiting_Status[
                                    Number(vehicleDetail.listing_status)
                                  ]
                                }
                                switchEnabled={vehicleDetail?.listing_status}
                                onChange={(event) => {
                                  updateVehicleStatuses(
                                    {
                                      ...vehicleDetail,
                                      internet_price:
                                        vehicleDetail?.vehicle?.internet_price,
                                      dealer_code: dealer_code,
                                      listing_status: event.target.checked,
                                    },
                                    "listingStatus"
                                  );
                                  actions.updateInventoryRow({
                                    ...vehicleDetail,
                                    listing_status: event.target.checked,
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {getStatusIcon(vehicleDetail?.vehicle_status)}
                              <span
                                className={`status-indicator ${statusIcon}`}
                              ></span>

                              {
                                vehicleDetail?.vehicle_status as keyof typeof Status
                              }
                            </TableCell>
                          </>
                        )}
                        <TableCell>{vehicleDetail?.vehicle?.type}</TableCell>
                        <TableCell>
                          <Box theme={theme} className="add-option">
                            {vehicleDetail.dealer_option_descriptions ? (
                              <Link
                                className="link"
                                variant="body2"
                                component={"a"}
                                onClick={(_e: any) => {
                                  setvehicleObject({
                                    ...vehicleDetail,
                                    dealer_code: dealer_code,
                                    internet_price:
                                      vehicleDetail?.vehicle?.internet_price,
                                  });
                                  handleClickProductOpen();
                                }}
                              >
                                {vehicleDetail?.dealer_option_descriptions}
                              </Link>
                            ) : (
                              <Link
                                className="link add"
                                variant="body2"
                                component={"a"}
                                onClick={(_e: any) => {
                                  setvehicleObject({
                                    ...vehicleDetail,
                                    dealer_code: dealer_code,
                                    internet_price:
                                      vehicleDetail?.vehicle?.internet_price,
                                  });
                                  handleClickProductOpen();
                                }}
                              >
                                <Icon name="AddIcon" /> Add Add-Ons
                              </Link>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {vehicleDetail?.vehicle?.mileage?.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          <Box theme={theme} sx={{ mr: 4.3 }}>
                            {defaultCurrency}{" "}
                            {vehicleDetail?.vehicle?.msrp?.toLocaleString()}{" "}
                          </Box>
                        </TableCell>
                        <TableCell className="editable-cell">
                          {editableCell === index ? (
                            <Box
                              theme={theme}
                              className="cell-content"
                              justifyContent={"flex-end"}
                            >
                              <Input
                                theme={theme}
                                label=""
                                type="text"
                                startAdornment={defaultCurrency}
                                id={`editInternetPrice${index}`}
                                dynamic={false}
                                value={vehicleDetail?.vehicle?.internet_price}
                                onBlur={(e) =>
                                  setEditedInternetPrice(e.target.value)
                                }
                                masking
                                maskDecimalScale={2}
                                maskNumeric
                                // scale={2}
                                // numeric
                              />
                              <Button
                                defaultBtn
                                iconText={<Icon name="TickIcon" />}
                                onClick={(_e) => {
                                  updateVehicleInternetPrice(
                                    vehicleDetail.vehicle.id,
                                    {
                                      ...vehicleDetail.vehicle,
                                      internet_price: parseFloat(
                                        editedInternetPrice
                                          .toString()
                                          .replace(/,/g, "")
                                      ),
                                    }
                                  );
                                  actions.updateInventoryRow({
                                    ...vehicleDetail,
                                    vehicle: {
                                      ...vehicleDetail.vehicle,
                                      internet_price: parseFloat(
                                        editedInternetPrice
                                          .toString()
                                          .replace(/,/g, "")
                                      ),
                                    },
                                  });
                                  setEditableCell(-1);
                                  setEditedInternetPrice(-1);
                                }}
                              />
                              <Button
                                defaultBtn
                                iconText={
                                  <Icon
                                    name="CloseBlack"
                                    onClick={(_e) => {
                                      setEditableCell(-1);
                                      setEditedInternetPrice(-1);
                                    }}
                                  />
                                }
                              />
                            </Box>
                          ) : (
                            <Box
                              theme={theme}
                              className="cell-content"
                              justifyContent={"flex-end"}
                            >
                              <span>
                                {defaultCurrency}{" "}
                                {vehicleDetail?.vehicle?.internet_price?.toLocaleString()}{" "}
                              </span>
                              <Button
                                defaultBtn
                                iconText={
                                  <Icon
                                    name="EditIcon"
                                    onClick={(_e) => {
                                      setEditedInternetPrice(
                                        vehicleDetail?.vehicle?.internet_price
                                      );
                                      setEditableCell(index);
                                    }}
                                  />
                                }
                              />
                            </Box>
                          )}
                        </TableCell>
                        {route != Provider.Hubex && (
                          <TableCell>
                            <Switch
                              theme={theme}
                              varient={"basic"}
                              label={
                                Daily_Update[
                                  Number(vehicleDetail?.daily_update)
                                ]
                              }
                              switchEnabled={vehicleDetail?.daily_update}
                              onChange={(event) => {
                                updateVehicleStatuses(
                                  {
                                    ...vehicleDetail,
                                    dealer_code: dealer_code,
                                    daily_update: event.target.checked,
                                  },
                                  "dailyUpdate"
                                );
                                actions.updateInventoryRow({
                                  ...vehicleDetail,
                                  daily_update: event.target.checked,
                                });
                              }}
                            />
                          </TableCell>
                        )}
                        <TableCell className="action-cell fixed-cell">
                          <Menu
                            theme={theme}
                            options={[
                              {
                                optionText: "Send Referral",
                                optionkey: "Send Referral",
                                optionValue: {},
                                disabled:
                                  !vehicleDetail?.listing_status ||
                                  vehicleDetail?.vehicle_status !==
                                    Status.Available,
                              },
                            ]}
                            handleOptionClick={() =>
                              handleShowReferralDrawer(vehicleDetail)
                            }
                            render={(onMenuSelection) => (
                              <Button
                                defaultBtn
                                iconText={<Icon name="MoreIcon" />}
                                onClick={onMenuSelection}
                              />
                            )}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </>
            }
          />
          {inventoryFilterData?.totalResults > 0 && (
            <PaginationWrap>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20]}
                component="div"
                count={inventoryFilterData?.totalResults ?? -1}
                rowsPerPage={pageSize}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  IconComponent: ChevronDown,
                  MenuProps: { disablePortal: true },
                }}
              />
            </PaginationWrap>
          )}
        </DataTableWrap>
        {popUpState && (
          <PersistentDrawer
            title={
              popUpOpenState === ActionOptions.ADD_VEHICLE
                ? "Add New Vehicle"
                : "Bulk Update"
            }
            openPopUp={popUpState}
            setPopUpState={setPopUpState}
            theme={theme}
            enabledPin={true}
            children={
              <>
                <AddNewVehicle
                  form={form}
                  assetMakeId={assetMakeId}
                  setAssetMakeId={setAssetMakeId}
                  setAssetModelId={setAssetModelId}
                  assetModelId={assetModelId}
                  setAssetTrimId={setAssetTrimId}
                  assetTrimId={assetTrimId}
                  popUpOpenState={popUpOpenState}
                  selected={selected}
                  setSellingPrice={setSellingPrice}
                  sellingPrice={sellingPrice}
                  setAssetTrimCode={setAssetTrimCode}
                  modelName={modelName}
                  dealer_code={dealer_code}
                  isBmwInventory={route === Provider.Hubex}
                />
              </>
            }
            customFooter={
              <Button
                theme={theme}
                primary
                type="submit"
                text={
                  popUpOpenState === ActionOptions.ADD_VEHICLE
                    ? "Save Vehicle"
                    : "Save Update"
                }
                fullWidth
                onClick={handleSubmit(handleSaveVehicle)}
              />
            }
          />
        )}
        <PersistentDrawer
          title={"Send Invite"}
          openPopUp={popUpInviteDrawer}
          setPopUpState={handleCloseInviteDrawer}
          theme={theme}
          enabledPin={true}
          children={
            <Grid theme={theme} container item columnSpacing={2} rowSpacing={3}>
              <Grid theme={theme} item md={6}>
                <Controller
                  name="fName"
                  control={referralForm.control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      testid="fName"
                      fullWidth
                      label={"First Name"}
                      placeholder={"First Name"}
                      type={"text"}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item md={6}>
                <Controller
                  name="lName"
                  control={referralForm.control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      testid="lName"
                      fullWidth
                      label={"Last Name"}
                      placeholder={"Last Name"}
                      type={"text"}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="email"
                  control={referralForm.control}
                  defaultValue={""}
                  rules={validation(
                    "Email",
                    true,
                    true,
                    EMAIL_REGEX,
                    ValidationMessages.INVALID_EMAIL
                  )}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      testid="email"
                      fullWidth
                      label={"Email Address"}
                      placeholder={"Email Address"}
                      type={"email"}
                      {...field}
                      error={errors?.email?.message as string}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="description"
                  control={referralForm.control}
                  defaultValue={""}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      label={"Description"}
                      type="text"
                      id="description"
                      {...field}
                      rows={3}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          }
          customFooter={
            <Button
              theme={theme}
              primary
              type="submit"
              text={"Send Invite"}
              fullWidth
              onClick={referralForm?.handleSubmit(handleSendReferral)}
            />
          }
        />
        <ImageViewer
          theme={theme}
          sliderImagesWithVisible={true}
          sliderImages={images}
          isLoading={false}
          singleTriggerNode={true}
          overlayCheck={true}
          visible={visibility}
          index={index}
          setIndex={setIndex}
          onClose={handleClose}
        />
        {openOptionDialog && (
          <AttachedOptions
            openPopup={openOptionDialog}
            vehicleData={vehicleObject}
            setopenOptionDialog={setopenOptionDialog}
            dealer_code={dealer_code}
            setModalClose={setModalClose}
          ></AttachedOptions>
        )}
        {openAddMoreInventoryDialog && (
          <InventoryPopup
            openPopup={openAddMoreInventoryDialog}
            inventoryData={inventoryData}
            setopenInventoryDialog={setOpenAddMoreInventoryDialog}
          ></InventoryPopup>
        )}
        {warningPopupState && (
          <ConfirmationDialog
            openPopUp={warningPopupState}
            onConfirm={handleBtn}
            setPopUpState={setWarningPopupState}
            confirmationTitle={DialogMessages.uploadTitle}
            confirmationText={confirmationText}
            primaryBtnText={DialogMessages.uploadBtnText}
            icon={<Icon name="ErrorTriangle" />}
          />
        )}
      </LayoutWithSideNav>
    </DrawerWrap>
  );
};
export default InventoryManagement;
