import { FC, Fragment, Key, memo, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, DuoTab, Icon, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, DuoTabWrap, VehicleList } from '@components'
import EditFees from 'controls/fees/edit-fees'
import AddOnOption from 'controls/addon-control/addon'
import { DeskingActionTypes, FinanceType, useDeskingContext } from './desking-context-provider'
import { useModal } from 'react-modal-hook'
import { useSearchParams } from 'react-router-dom'
import FinanceInsuranceControl from 'controls/FinanceInsuranceControl/finance-insurance-control'
import { useStoreContext } from '@store/storeContext'
import { AvailableFinanceTypes } from '@helpers/enums'
import { IStipulationResponse } from '@models'
import { StipCodes } from '@helpers/enums/stipulation-type.enum'
import { CustomTheme } from '@styles/customTheme'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'

export enum TabName {
  FNI = 'f&i',
  OPTIONS = 'add-ons',
  FEES = 'fees',
  MSP = 'Maint. & Service Products'
}

export const Tabs = {
  [TabName.FNI]: 0,
  [TabName.OPTIONS]: 1,
  [TabName.FEES]: 2,
  [TabName.MSP]: 3
}

interface Fni {
  finance: any[]
  lease: any[]
}

export interface IExtrasPopupProps {
  vin: string
  vehiclesData: Map<string, any>
  finance_type: FinanceType
  tabName: TabName
  contract_term: number
  data: {
    dealer_options: any[]
    fees: Object
    fni?: Fni
  }
  hide: () => void
  saveExtrasData: (extrasData: any) => void
}

export const ExtrasPopup: FC<Partial<IExtrasPopupProps>> = ({
  vin,
  vehiclesData,
  finance_type,
  tabName,
  data,
  contract_term,
  hide,
  saveExtrasData
}) => {
  const theme = useTheme()
  const defaultTab = Tabs[tabName as TabName] ?? -1

  const { dealer_options = [], fees = {}, fni = { finance: [], lease: [] } } = data || {}

  const { states } = useStoreContext()

  const [extrasData, setExtrasData] = useState({
    dealer_options,
    fees,
    fni
  })

  const updateExtrasData = (type: 'dealer_options' | 'fees', newData: any) => {
    setExtrasData((prev) => ({ ...prev, [type]: newData }))
  }

  const [fniData, setFniData] = useState(fni)
  const [addedItems, setAddedItems] = useState<any>([])
  const defaultCurrency = (states?.dealDesk?.defaultCurrency as { symbol: string })?.symbol
  const defaultDealerTerms = states?.dealDesk?.contractTerms?.find(
    (item) => item.id === states?.dealDesk?.dealerPreferences?.default_term_id
  )
  const apr = (vehiclesData?.get(vin as string)?.program ?? {})?.[finance_type as string]?.find(
    (p: any) => p?.contract_term === (contract_term ?? defaultDealerTerms?.term)
  )?.final_customer_rate

  const preInstalledDealerOptions =
    vehiclesData?.get(vin as string)?.preInstalledDealerOptions ?? []
  const default_term_id =
    finance_type === AvailableFinanceTypes.FINANCE
      ? states?.dealDesk?.dealerPreferences?.default_term_finance_id
      : states?.dealDesk?.dealerPreferences?.default_term_lease_id
  const terms = (
    finance_type === AvailableFinanceTypes.FINANCE
      ? states?.dealDesk?.contractTermOptionsFinance
      : states?.dealDesk?.contractTermOptionsLease
  )?.find((term: any) => term.id === default_term_id)?.term

  return (
    <CustomTheme key={'ExtrasPopup'} theme={theme} className="custom-theme">
      <Dialog
        theme={theme}
        variant={undefined}
        size="xl"
        title="Add Products"
        open={true}
        onCloseDialog={hide}
        disablePortal={true}
        customFooter={
          <Button
            theme={theme}
            primary
            text="Continue"
            onClick={() => {
              saveExtrasData?.({ ...extrasData, fni: fniData })
            }}
          />
        }
      >
        <DuoTabWrap theme={theme} className="duo-tab-wrap align-center sm fixed-child-tab-height">
          <DuoTab
            theme={theme}
            defaultTabIndex={defaultTab}
            items={[
              {
                title: 'F&I',
                content: (
                  <FinanceInsuranceControl
                    dealerProfileData={states?.dealDesk?.dealerProfile}
                    company_id={states?.dealerInfo?.company_id}
                    defaultCurrency={defaultCurrency}
                    isMSP={false}
                    fniData={fniData}
                    fni={fni}
                    setFniData={setFniData}
                    terms={terms}
                    apr={apr}
                    selectedFinanceType={finance_type as 'Finance' | 'Lease'}
                    selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
                  />
                )
              },
              {
                title: 'Add-Ons',
                content: (
                  <AddOnOption
                    defaultOptions={dealer_options}
                    preInstalledDealerOptions={preInstalledDealerOptions}
                    defaultCurrency={defaultCurrency}
                    modelName={vehiclesData?.get(vin as string)?.vehicle?.model}
                    setSelectedObjectsArray={(newOptions: any) =>
                      updateExtrasData('dealer_options', newOptions)
                    }
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    vin={vin ?? ''}
                  />
                )
              },
              {
                title: 'Fees',
                content: (
                  <EditFees
                    dealerFees={extrasData?.fees}
                    financeType={finance_type ?? 'Finance'}
                    defaultCurrency={defaultCurrency}
                    setDealerFees={(newFees: any) => updateExtrasData('fees', newFees)}
                  />
                )
              },
              {
                title: 'Maint. & Service Products',
                content: (
                  <FinanceInsuranceControl
                    dealerProfileData={states?.dealDesk?.dealerProfile}
                    company_id={states?.dealerInfo?.company_id}
                    defaultCurrency={defaultCurrency}
                    isMSP={true}
                    fniData={fniData}
                    fni={fni}
                    setFniData={setFniData}
                    terms={terms}
                    apr={apr}
                    selectedFinanceType={finance_type as 'Finance' | 'Lease'}
                    selectedVehicle={vehiclesData?.get(vin as string)?.vehicle}
                  />
                )
              }
            ]}
          />
        </DuoTabWrap>
      </Dialog>
    </CustomTheme>
  )
}

export const useExtrasModal = () => {
  const [params, setParams] = useState<Omit<IExtrasPopupProps, 'hide'> | null>(null)
  const { vin, vehiclesData, data, finance_type, tabName, contract_term, saveExtrasData } =
    params ?? {}

  const [showPopup, hidePopup] = useModal(
    () => (
      <ExtrasPopup
        vin={vin}
        vehiclesData={vehiclesData}
        data={data}
        finance_type={finance_type}
        contract_term={contract_term}
        tabName={tabName ?? TabName.OPTIONS}
        hide={hideExtrasPopup}
        saveExtrasData={saveExtrasData}
      />
    ),
    [vin, vehiclesData, data, finance_type, tabName, contract_term, saveExtrasData]
  )

  useEffect(() => {
    if (Object.keys(params ?? {}).length > 0) {
      showPopup()
    }
  }, [params])

  const showExtrasPopup = (params: Omit<IExtrasPopupProps, 'hide'>) => {
    setParams(params)
  }

  const hideExtrasPopup = () => {
    hidePopup()
    setParams(null)
  }

  return { showExtrasPopup, hideExtrasPopup }
}

interface VlItemProps {
  data: any
  nameKey: string
  priceKey: string
}

const VlItem: FC<VlItemProps> = ({ data, nameKey, priceKey }) => {
  const theme = useTheme()
  const { formatCurrency } = useDeskingContext()

  return (
    <Fragment key={'VlItem'}>
      {nameKey !== 'fee_name' ? (
        <Box theme={theme} className="vl-img-wrap">
          <img
            src={
              data?.image?.[0]?.location ?? require('../../../src/public/assets/images/no-img.svg')
            }
            alt="Car"
          />
        </Box>
      ) : null}
      <Box theme={theme} className="vl-details">
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={data?.[nameKey]}
        />
        <Typography
          theme={theme}
          variant="caption"
          component="p"
          className=" text-overflow vl-title-ul"
          children={<b>{formatCurrency(data?.is_price_inclusive ? 0 : data?.[priceKey] || data?.price || 0)}</b>}
        />
      </Box>
    </Fragment>
  )
}

const AddOpptions: FC<{ tabName: keyof typeof Tabs }> = ({ tabName }) => {
  const theme = useTheme()
  const {
    state: {
      vehiclesData,
      vehiclesVins,
      finance_type,
      isVehicleSelectedAndOrderSaved,
      preview,
      submission_tier,
      stipulationData: stipulations,
      selection
    },
    dispatch
  } = useDeskingContext()
  const [searchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')

  const vin = isVehicleSelectedAndOrderSaved ? preview.vin : vehiclesVins[0]
  const { dealer_options, fees, fni } = vehiclesData?.get(vin) ?? {}

  const { showExtrasPopup, hideExtrasPopup } = useExtrasModal()

  const canUserEdit = useEditPermissionHook()

  const handleExtrasData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.UPDATE_OPTIONS_FEES_FNI,
      payload: {
        vinUOFF: vin,
        // fni: [],
        ...data
      }
    })
    hideExtrasPopup()
  }

  const isTerms: boolean = stipulations?.some(
    (item: IStipulationResponse) => item?.stipulation_code === StipCodes.TERMS
  )

  const renderItems = () => {
    let dataList = []
    let nameKey = ''
    let priceKey = ''

    const getFnIData = (isMSP: boolean) => {
      const fnIData =
        finance_type === AvailableFinanceTypes.FINANCE ? fni?.finance || [] : fni?.lease || []
      return fnIData.filter(
        (fniItem: any) =>
          fniItem.MSP === isMSP ||
          fniItem.is_msp === isMSP ||
          ((fniItem.MSP || fniItem.is_msp) && !isMSP && fniItem?.bundle_id)
      )
    }

    const hasAppliedPriceKey =
      finance_type === AvailableFinanceTypes.FINANCE
        ? fni?.finance?.length > 0 &&
          fni.finance.every((item: any) => item?.applied_price !== undefined)
        : fni?.lease?.length > 0 &&
          fni.lease.every((item: any) => item?.applied_price !== undefined)

    switch (tabName) {
      case 'add-ons':
        dataList = dealer_options || []
        nameKey = 'product_name'
        priceKey = orderReferenceId ? 'applied_price' : 'price'
        break
      case 'fees':
        dataList = fees?.[finance_type] || []
        nameKey = 'fee_name'
        priceKey = orderReferenceId ? 'applied_price' : 'default_amount'
        break
      case 'f&i':
        dataList = getFnIData(false)
        nameKey = 'product_name'
        priceKey = hasAppliedPriceKey ? 'applied_price' : 'price'
        break
      case 'Maint. & Service Products':
        dataList = getFnIData(true)
        nameKey = 'product_name'
        priceKey = hasAppliedPriceKey ? 'applied_price' : 'price'
        break
      default:
        return null
    }
    return dataList.map((value: any, colIndex: Key | null | undefined) => (
      <Box theme={theme} className="vl-item sm item-li" mb={1} key={`VlItem${colIndex}`}>
        <VlItem data={value} nameKey={nameKey} priceKey={priceKey} />
      </Box>
    ))
  }

  return (
    <Fragment key={'AddOpptions'}>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          disabled={
            !vin ||
            !Object.keys(vehiclesData.get(vin) ?? {}).length ||
            Boolean(submission_tier) ||
            isTerms ||
            !canUserEdit
          }
          text={'Add ' + tabName}
          size="medium"
          onClick={() => {
            showExtrasPopup({
              vin,
              vehiclesData,
              finance_type,
              tabName,
              contract_term: selection?.quoteParams?.contract_term,
              data: {
                dealer_options,
                fees: fees,
                fni: {
                  finance: fni?.finance || [],
                  lease: fni?.lease || []
                }
              },
              saveExtrasData: handleExtrasData
            })
          }}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <Box theme={theme} className="vl-scroll">
          {renderItems()}
        </Box>
      </VehicleList>
    </Fragment>
  )
}

export default memo(AddOpptions)
