import "./root.component.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Router } from "./router";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { StyledEngineProvider } from "@mui/material";
// import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import { unityTheme } from "@ntpkunity/controls";
// import { QueryClientProvider, QueryClient } from "react-query";
// import StoreProvider from "../src/store/storeContext";
// import BaseLayout from "@layout/components/baseLayout/baseLayout";
import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
//import { SsoValidator } from "@ntpkunity/controls-ums";
import { AuthProvider } from 'oidc-react';

export const msalInstance = new PublicClientApplication(msalConfig);

import { HomeComponent } from "./components/home";

const oidcConfig = {
  onSignIn: (data) => {
    // Redirect?
    console.log(data);
  },
  authority:"https://dev-api-k8s.netsolapp.io/user/configuration/f454a4cb-14b7-4f0e-b557-d80bd15c6134",
  clientId:"f454a4cb-14b7-4f0e-b557-d80bd15c6134",
  redirectUri:"https://dev.netsolapp.io/auth/success",
  scope:"profile email openid b2xroles bmwids"
};


const APPLICATION_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/ccp/test-url" element={<AuthProvider {...oidcConfig} ><HomeComponent /></AuthProvider >} />
        <Route path="/ccp/home" element={<HomeComponent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default function Root(props) {
  return <App {...props} />;
}

const App = () => {
  // const queryClient = new QueryClient({
  //   defaultOptions: {
  //     queries: {
  //       staleTime: Infinity,
  //       retry: 1,
  //     },
  //   },
  // });
  return (
    <>
      <APPLICATION_ROUTES />
      {/*<StoreProvider>*/}
      {/* <MsalProvider instance={msalInstance}> */}
      {/*<QueryClientProvider client={queryClient}>*/}
      {/*<StyledEngineProvider injectFirst>*/}
      {/* <MuiThemeProvider theme={unityTheme}>*/}
      {/*<BaseLayout>*/}
      {/*<BrowserRouter>*/}
      {/*<Router />*/}
      {/*</BrowserRouter>*/}
      {/*<BrowserRouter>*/}
      {/*  <APPLICATION_ROUTES />*/}
      {/*</BrowserRouter>*/}
      {/*</BaseLayout>*/}
      {/*</MuiThemeProvider>*/}
      {/*</StyledEngineProvider>*/}
      {/*</QueryClientProvider>*/}
      {/* </MsalProvider> */}
      {/* </StoreProvider>*/}
    </>
  );
};
