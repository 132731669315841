// @ts-nocheck
import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Input, Box, Button, Icon, Dialog, CircleLoader } from '@ntpkunity/controls'
import { TableList } from '@components'
import {
  DeskingActionTypes,
  FinanceTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import {
  AvailableFinanceTypes,
  FeeHandlingType,
  InstallationMode,
  AddressType
} from '@helpers/enums'
import { calculateTotalOfPayments, roundToTwoDecimal } from './utils'
import { useStoreContext } from '@store/storeContext'
import { useDebouncedState } from '@apis/dealer-configurations.service'
import { StipCodes } from '@helpers/enums/stipulation-type.enum'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'
import { useGetTaxRates } from '@apis/integrations.service'
import { ITaxRate } from 'controls/multi-lender-dialog'
import { Label } from './finance-lease-calculator'

export const UpdateCalculationData: FC<{
  property: string
  value: string | number
  calculationData: any
  disable?: boolean
}> = ({ property, value, calculationData, disable = false }) => {
  const theme = useTheme()

  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const { state: deskingState, dispatch } = useDeskingContext()
  const [debouncedState, setState, state] = useDebouncedState(value)
  const finance_type = deskingState?.finance_type
  const isTerms: boolean = deskingState.stipulationData?.some(
    (item: IStipulationResponse) => item?.stipulation_code === StipCodes.TERMS
  )
  useEffect(() => {
    if (debouncedState !== null && Number(value) !== Number(debouncedState)) {
      const debouncedValue = Number(debouncedState ?? value)
      const updatedValue = debouncedValue > 0 ? debouncedValue : undefined
      const updatedCalculationData = {
        ...calculationData,
        [property]: updatedValue,
        tax_amount: updatedValue,
        tax_on_selling_price: updatedValue,
        tspk: updatedValue
      }

      dispatch({
        type: DeskingActionTypes.UPDATE_CALCULATION_DATA,
        payload: {
          property,
          valueUCD: updatedCalculationData
        }
      })
    }
  }, [debouncedState, dispatch, calculationData, property, value])

  return (
    <Input
      theme={theme}
      type="text"
      fullWidth
      startAdornment={defaultCurrency?.symbol}
      value={state}
      masking
      maskNumeric
      maskDecimalScale={2}
      onChange={(e) => setState(Number(e))}
      disabled={
        finance_type === AvailableFinanceTypes.LEASE ||
        isTerms ||
        Boolean(deskingState?.submission_tier) ||
        disable
      }
    />
  )
}

const PaymentDetails: FC = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { state, formatCurrency } = useDeskingContext()
  const { mutate: getTaxRates, data: taxRates, isLoading: getTaxLoading } = useGetTaxRates()
  const [openDialog, setOpenDialog] = useState(false)

  const canUserEdit = useEditPermissionHook()

  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0

  const vin = isVehicleSelectedAndOrderSaved ? state.preview.vin : state.selection.vin
  const finance_type = isVehicleSelectedAndOrderSaved
    ? state.preview.finance_type
    : state.selection.finance_type ?? state.finance_type

  const vehicleData = state.vehiclesData.get(vin)
  const fees = (vehicleData?.fees ?? {})?.[finance_type] ?? []

  const program = (vehicleData?.program ?? {})?.[finance_type]?.find(
    (p) =>
      p?.contract_term === state?.selection?.quoteParams?.contract_term &&
      (finance_type === FinanceTypes.FINANCE ||
        p?.mileage === state?.selection?.quoteParams?.annual_usage)
  )
  const fni = (vehicleData?.fni ?? {})?.[finance_type?.toLowerCase()] ?? []

  const calculationsData = isVehicleSelectedAndOrderSaved
    ? state.preview.calculationsBoxes
    : isVehicleSelected
    ? state?.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
      ? state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]
          .calculationsBoxes[state.selection.row]?.[state.selection.col]
      : state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type][
          state?.selection?.col
        ]?.calculationsBoxes
    : {}

  const quoteParams = isVehicleSelectedAndOrderSaved
    ? state.preview.quote_params
    : isVehicleSelected
    ? state?.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
      ? state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type]
          ?.calculationsBoxes[state.selection?.row][0]
      : state[state?.selection?.vehicleForComparison]?.[state?.selection?.finance_type][
          state.selection.col
        ].quote_params
    : {}

  const optionsPrice = (vehicleData?.dealer_options ?? [])?.reduce(
    (total: number, currentItem: any) =>
      total +
      ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
        !currentItem.is_price_inclusive) ||
      currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
        ? currentItem?.applied_price ?? currentItem?.price
        : 0),
    0
  )

  const fniProductsPrice = (fni ?? [])
    .filter(
      (currentItem) =>
        currentItem?.MSP === false ||
        currentItem?.is_msp === false ||
        ((currentItem?.MSP || currentItem?.is_msp) && currentItem?.bundle_id)
    )
    .reduce(
      (total: number, currentItem: any) =>
        total + roundToTwoDecimal(currentItem?.applied_price ?? currentItem?.price ?? 0),
      0
    )

  const mspProductsPrice = (fni ?? [])
    .filter(
      (currentItem) =>
        (currentItem?.MSP === true || currentItem?.is_msp === true) && !currentItem?.bundle_id
    )
    .reduce(
      (total: number, currentItem: any) =>
        total + roundToTwoDecimal(currentItem?.applied_price ?? currentItem?.price ?? 0),
      0
    )

  const financedFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.FINANCE
        ? total + (fee?.applied_price ?? fee?.default_amount ?? 0)
        : total,
    0
  )

  const upfrontFeeSum = fees.reduce(
    (total: number, fee: any) =>
      fee?.fee_handling?.toLowerCase() === FeeHandlingType.UPFRONT
        ? total + (fee?.applied_price ?? fee?.default_amount)
        : total,
    0
  )

  const tradeInAmount =
    Object.keys(state.tradeIn ?? {}).length > 0
      ? state.tradeIn?.trade_in_amount - state.tradeIn?.payoff_amount
      : 0

  const leaseRebates = isVehicleSelectedAndOrderSaved
    ? state?.order?.rebate ?? 0
    : program?.rebates ?? 0

  const tradeInInEquity = tradeInAmount < 0 ? tradeInAmount : 0

  const financeAmount =
    (financedFeeSum ?? 0) +
    (fniProductsPrice ?? 0) -
    tradeInInEquity +
    (calculationsData?.tax_amount ?? 0) +
    Number(calculationsData?.selling_price ?? 0) +
    (optionsPrice ?? 0) +
    (mspProductsPrice ?? 0)

  const totalOfPayments =
    Object.keys(program ?? {}).length > 0
      ? calculateTotalOfPayments(
          calculationsData?.monthly_payment ?? 0,
          quoteParams?.contract_term ?? 0,
          program,
          calculationsData?.selling_price ?? 0
        )
      : 0

  const buyRate = state.hasUnsavedOrderChanges
    ? state?.preview?.quote_params?.apr
    : isVehicleSelectedAndOrderSaved
    ? state.order.margin
    : state.selection?.quoteParams?.apr ?? program?.interest_chart?.[0]?.margin ?? 0

  const dealerMargin = isVehicleSelected
    ? (state.finance_type === FinanceTypes.FINANCE
        ? states?.dealDesk?.dealerPreferences?.default_margin_rate_finance
        : states?.dealDesk?.dealerPreferences?.default_margin_rate_lease) ?? 0
    : 0

  const {
    state: { taxes, isTaxUpdated, vehiclesVins },
    dispatch
  } = useDeskingContext()

  const [inputValues, setInputValues] = useState()

  const garagging_address = (state?.customer?.customer_addresses ?? []).find(
    (currentItem) =>
      currentItem?.address_type === AddressType.GARAGING || currentItem?.is_garaging === true
  )

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleInputChange = (key, value) => {
    setInputValues({
      ...inputValues,
      [finance_type]: {
        ...inputValues[finance_type],
        [key]: parseFloat(value)
      }
    })
  }

  const handleOpen = () => {
    const body = {
      address: `${garagging_address?.address_line_1}, ${garagging_address?.city}`,
      zip_code: Number(garagging_address?.zip_code)
    }
    const isEmptyTaxes = (taxes) =>
      Object.values(taxes).every((value) => Object.keys(value).length === 0)
    if (
      (JSON.stringify((inputValues || {})[finance_type]) !==
        JSON.stringify((taxes || {})[finance_type]) &&
        !isVehicleSelectedAndOrderSaved) ||
      isEmptyTaxes(state?.taxes)
    ) {
      getTaxRates(body, {
        onSuccess(response: any) {
          const updatedTaxRates = {
            Finance: response?.state_fee_tax?.finance
          }
          setInputValues(updatedTaxRates)
          dispatch({
            type: DeskingActionTypes.SAVE_TAXES,
            payload: updatedTaxRates
          })
        }
      })
    } else if (!isTaxUpdated && !isVehicleSelectedAndOrderSaved) {
      getTaxRates(body, {
        onSuccess(response: any) {
          const updatedTaxRates = {
            Finance: response?.state_fee_tax?.finance
          }
          setInputValues(updatedTaxRates)
          dispatch({
            type: DeskingActionTypes.SAVE_TAXES,
            payload: updatedTaxRates
          })
        }
      })
    } else {
      setInputValues(taxes)
      dispatch({
        type: DeskingActionTypes.UPDATE_TAXES,
        payload: false
      })
    }
    setOpenDialog(true)
  }

  const handleContinue = () => {
    if (
      JSON.stringify((inputValues || {})[finance_type]) !==
      JSON.stringify(taxes || {})[finance_type]
    ) {
      if (!isTaxUpdated) {
        dispatch({
          type: DeskingActionTypes.UPDATE_TAXES,
          payload: true
        })
      }
    }
    if (inputValues) {
      dispatch({
        type: DeskingActionTypes.SAVE_TAXES,
        payload: inputValues
      })
    }

    setOpenDialog(false)
  }

  function formatTaxLabel(label) {
    if (!label) return ''
    return label
      .split('_')
      .map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
      .join(' ')
  }
  return (
    <>
      <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
        <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
          <TableList
            listTitle="FA/Gross Capitalized Cost"
            items={[
              {
                title: 'Selling Price',
                value: formatCurrency(calculationsData?.selling_price ?? 0)
              },
              {
                title: 'Add-Ons',
                value: formatCurrency(optionsPrice ?? 0)
              },
              {
                title: 'Maint. & Service Products',
                value: formatCurrency(mspProductsPrice ?? 0)
              },
              {
                title: 'F&I Products',
                value: formatCurrency(fniProductsPrice ?? 0)
              },
              {
                title: 'Fees',
                value: formatCurrency(financedFeeSum ?? 0)
              },
              {
                title: 'Trade-In Inequity',
                value: formatCurrency(tradeInInEquity)
              },
              {
                title: 'Taxes Capitalization',
                value: (
                  <Box theme={theme} className="table-list-li-form u-form-text-right">
                    <UpdateCalculationData
                      key={`tax_on_selling_price-${calculationsData?.tax_amount ?? 0}`}
                      property="tax_on_selling_price"
                      value={calculationsData?.tax_amount ?? 0}
                      calculationData={calculationsData}
                      disable={!canUserEdit || calculationsData?.tax_amount == null}
                    />
                    {garagging_address &&
                    vehiclesVins.length > 0 &&
                    state.finance_type === FinanceTypes.FINANCE ? (
                      <Button
                        theme={theme}
                        defaultBtn
                        size="xs"
                        className="btn-dialog"
                        iconText={<Icon name="MoreIcon" />}
                        onClick={handleOpen}
                      />
                    ) : null}
                    <Dialog
                      variant={undefined}
                      size="xs"
                      title="Rates"
                      open={openDialog}
                      onCloseDialog={handleClose}
                      customFooter={
                        <>
                          <Button theme={theme} secondary text={'Cancel'} onClick={handleClose} />
                          <Button
                            theme={theme}
                            disabled={false}
                            type="submit"
                            primary
                            text="Continue"
                            onClick={handleContinue}
                          />
                        </>
                      }
                      theme={theme}
                      children={
                        <Box theme={theme} minHeight="100px">
                          {getTaxLoading ? (
                            <CircleLoader theme={theme} size="md" />
                          ) : (
                            inputValues &&
                            Object?.keys((inputValues || {})[finance_type])?.map((key, index) => (
                              <Input
                                key={index}
                                theme={theme}
                                fullWidth
                                maskNumeric
                                masking
                                type={'text'}
                                endAdornment="%"
                                label={formatTaxLabel(key)}
                                placeholder="Type here..."
                                value={inputValues[finance_type][key]?.toLocaleString(undefined, {
                                  maximumFractionDigits: 4,
                                  minimumFractionDigits: 4
                                })}
                                onChange={(e) => handleInputChange(key, e)}
                              />
                            ))
                          )}
                        </Box>
                      }
                    />
                  </Box>
                )
              },
              {
                title: 'Total',
                value: formatCurrency(calculationsData?.gross_capitalized_cost ?? 0),
                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="Capitalized Cost Reduction"
            items={[
              {
                title: 'Down Payment',
                value: formatCurrency(calculationsData?.down_payment ?? 0)
              },
              {
                title: 'Trade-In Equity',
                value: formatCurrency(tradeInAmount > 0 ? tradeInAmount : 0)
              },
              {
                title: 'Rebates & Incentives',
                value: formatCurrency(leaseRebates ?? 0)
              },
              {
                title: 'Total',
                value: formatCurrency(calculationsData?.capitalized_cost_reduction ?? 0),

                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="NFA/Adjusted Capitalized Cost"
            items={[
              {
                title: 'Balance',
                value: formatCurrency(calculationsData?.adjusted_capitalized_cost ?? 0),
                hasBoldText: true
              }
            ]}
          />
        </Grid>
        <Grid theme={theme} item lg={6} md={6} sm={6} xs={12}>
          <TableList
            listTitle="Due at Signing"
            items={[
              {
                title: 'Down Payment',
                value: formatCurrency(calculationsData?.down_payment ?? 0)
              },
              {
                title: 'First Periodic Payment',
                value: formatCurrency(calculationsData?.monthly_payment ?? 0)
              },
              {
                title: 'Upfront Fees',
                value: formatCurrency(upfrontFeeSum ?? 0)
              },
              {
                title: 'Total',
                value: formatCurrency(calculationsData?.due_at_signing ?? 0),
                hasBoldText: true
              }
            ]}
          />
          <TableList
            listTitle="Payment Details"
            items={[
              {
                title: 'Monthly Payment',
                value: formatCurrency(calculationsData?.monthly_payment ?? 0)
              },
              {
                title: 'Term',
                value: `${quoteParams?.contract_term ?? '0'}`
              },
              {
                title: 'Buy Rate',
                value: `${Number(buyRate ?? 0)?.toFixed(5)}%`
              },
              {
                title: 'Markup Rate',
                value: `${dealerMargin?.toFixed(5)}%`
              },
              {
                title: 'Sell Rate',
                value: `${(Number(buyRate ?? 0) + Number(dealerMargin))?.toFixed(5)}%`
              },
              ...(finance_type === FinanceTypes.FINANCE
                ? [
                    {
                      title: 'Days to 1st Payment',
                      value: (
                        <Box theme={theme} className="table-list-li-form u-form-text-right">
                          <Input theme={theme} type="number" disabled value="30" />
                        </Box>
                      )
                    }
                  ]
                : []),
              {
                title: 'Financed Charges',
                value: formatCurrency(financedFeeSum ?? 0)
              },
              {
                title: 'Total of Payments',
                value: formatCurrency(totalOfPayments ?? 0),
                hasBoldText: true
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PaymentDetails
